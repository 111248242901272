import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  licenseInfo: {
    licenseType: '',
    licenseExpire: '',
    isLicenseValid: null,
    errorCode: '0',
  },
};

const slice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    setLicenseInfo: (state, action) => {
      state.licenseInfo = action.payload;
    },
  },
});

export const { setLicenseInfo } = slice.actions;

export default slice.reducer;
