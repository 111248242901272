import React, {useEffect, useState, useReducer} from 'react'
import classes from "./ClientActivation.module.css"
import Modal, {modalReducer, defaultModalData} from '../UI/Modal'
import { httpSetClientLicenseKey, httpCreateLicenseRequest, httpClientOfflineActivate } from '../api/license'
import useHttp from '../CustomHook/use-http'
import { downloadBlobFile, readResponseTextAsync } from '../common'

const ACTIVATION_TYPE = {
    ONLINE: "online",
    OFFLINE: "offline"
}

const parseLicenseActivationResult = (errorSetLicensekey) => {
    let errorMessage = null
    const errorDetail = JSON.parse(errorSetLicensekey)

    if(errorDetail.status == 422){
        errorMessage = "Error : Invalid license key"
    }else if(errorDetail.status == 402){
        errorMessage = `Error : License is expired`
    }else if(errorDetail.error?.err_msg){
        errorMessage = `Error : ${errorDetail.error.err_msg}`
    }

    return errorMessage
}

const BodyOnline = (props) => {
    const {onActivate, dispatchModalActivatationData} = props
    const [key, setKey] = useState("")

    const { sendRequest: reqSetLicensekey,
        status: statusSetLicensekey,
        error: errorSetLicensekey,
    } = useHttp(httpSetClientLicenseKey);

    const submitLicense = () => {
        reqSetLicensekey({
            key:  key
        })

        dispatchModalActivatationData({type: "data", val: {isProcessing: true}})

    }

    useEffect(()=>{
        if (errorSetLicensekey) {

            onActivate("fail", parseLicenseActivationResult(errorSetLicensekey))

        }else if (statusSetLicensekey == 'success') {
            onActivate("success")
        }
    },[statusSetLicensekey, errorSetLicensekey])

    const updateKey = (value) => {
        setKey(value)
    }

    return (
        <React.Fragment>
            <div className={classes['content--wrapper']}>
                <div className={classes['content-body--wrapper']}>
                    <h5>License Key</h5>
                    <div className={classes['content-license--wrapper']}>

                        <div className={classes['key--wrapper']}>
                            <input className={`${classes['key-input']} allowed-without-license`}
                                value={key}
                                onChange={(e) => {updateKey(e.target.value)}}
                                autoFocus={true}
                            />
                        </div>
                        <div>
                            ***You need to have an internet connection to activate the license.
                        </div>
                    </div>
                    <div className={classes['button--wrapper']}>
                        <button className='btn btn-md btn__outline--gray allowed-without-license'
                            onClick={submitLicense}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

const BodyOffline = (props) => {
    const [activationState, setActivationState] = useState("download")

    const { sendRequest: reqCreateLicenseKey,
        status: statusCreateLicenseKey,
        data: dataCreateLicenseKey,
        error: errorCreateLicenseKey,
    } = useHttp(httpCreateLicenseRequest);

    const {
        sendRequest: sendOfflineLicense,
        status: statusOfflineLicense,
        data: dataOfflineLicense,
        error: errorOfflineLicense,
    } = useHttp(httpClientOfflineActivate);

    useEffect(()=>{
        if (errorCreateLicenseKey) {
            toastr.error("Error get license requirement file")
        }else if (statusCreateLicenseKey == 'success') {
            readResponseTextAsync(dataCreateLicenseKey).then(license => {
                const filename = dataCreateLicenseKey.headers.get('Content-Disposition')
                    .split(';')
                    .find(n => n.includes('filename='))
                    .replace('filename=', '')
                    .replace(/"/g, '')
                    .trim()
                ;

                const file = new File([license], filename)

                downloadBlobFile(filename, file);
                setActivationState("upload")
            })
        }
    },[statusCreateLicenseKey, dataCreateLicenseKey, errorCreateLicenseKey])


    useEffect(()=>{
        if (errorOfflineLicense) {

            props.onActivate("fail", parseLicenseActivationResult(errorOfflineLicense))

        }else if (statusOfflineLicense == 'success') {
            props.onActivate("success")
        }
    },[statusOfflineLicense, errorOfflineLicense])

    const onOfflineFileChanged = (e) => {

        if(e.target.files.length == 0){
            return
        }

        const file = e.target.files[0]

        if (file.type && file.type != ('text/plain') && !file.name.endsWith(".lic")) {
            toastr.error("Only .lic is supported")
            return
        }

        const formData = new FormData();
        formData.append(
            "license_file",
            file,
        );

        sendOfflineLicense({
            data: formData
        })

    }


    return (
        <React.Fragment>
            <div className={classes['content--wrapper']}>
                <div className={classes['content-body--wrapper']}>

                    <div className={classes['file--wrapper']}>
                        {activationState == "download" &&
                            <React.Fragment>
                                <div>
                                    Download license requirement file <a onClick={reqCreateLicenseKey} className={classes['link']} >here</a> and upload this file into PEGA AI portal.
                                </div>
                                <br/>
                                <br/>
                                <div>
                                    Already have the license key? Submit it &nbsp;

                                    <input type="file" style={{display: "none"}} id="input--activation-file"
                                        multiple={false}
                                        accept=".lic"
                                        onChange={onOfflineFileChanged}
                                    />
                                    <a className={`start_label_link blink_me ${classes['link']}`}
                                        onClick={() => document.getElementById("input--activation-file").click()}
                                    >
                                        here
                                    </a>
                                </div>
                            </React.Fragment>
                        }

                        {activationState == "upload" &&
                            <div>
                                After you get the license file from PEGA AI portal, you need to submit it &nbsp;
                                <input type="file" style={{display: "none"}} id="input--activation-file"
                                    multiple={false}
                                    accept=".lic"
                                    onChange={onOfflineFileChanged}
                                />
                                <a className={`start_label_link blink_me ${classes['link']}`}
                                    onClick={() => document.getElementById("input--activation-file").click()}
                                >
                                    here
                                </a>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

const BodyLicense = (props) => {
    const {onActivate, dispatchModalActivatationData} = props
    const [activationMode, setActivationMode] = useState(ACTIVATION_TYPE.ONLINE)

    if(activationMode == ACTIVATION_TYPE.ONLINE){
        return (
            <React.Fragment>
                <div style={{textAlign: "right"}}>
                    <a onClick={() => {setActivationMode(ACTIVATION_TYPE.OFFLINE)}} className={classes['link-switch-mode']}>Offline Activation</a>
                </div>
                <BodyOnline onActivate={onActivate} dispatchModalActivatationData={dispatchModalActivatationData}/>
            </React.Fragment>
        )
    }
    else if(activationMode == ACTIVATION_TYPE.OFFLINE){
        return (
            <React.Fragment>
                <div style={{textAlign: "right"}}>
                    <a onClick={() => {setActivationMode(ACTIVATION_TYPE.ONLINE)}} className={classes['link-switch-mode']}>Online Activation</a>
                </div>
                <BodyOffline onActivate={onActivate} dispatchModalActivatationData={dispatchModalActivatationData}/>
            </React.Fragment>
        )
    }
}

const BodyResult = (props) => {
    const {result, msg} = props

    return (
        <React.Fragment >
            <div className={classes['result-content--wrapper']}>
                <div className={`${classes['content-body--wrapper']} ${classes['content-result--wrapper']}`}>
                    <div className={classes['success-animation']}>

                        {result =="success" &&
                            <React.Fragment>
                                <svg className={`${classes['checkmark']} ${classes['checkmark--success']}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className={`${classes['checkmark__circle']} ${classes['checkmark__circle--success']}`} cx="26" cy="26" r="25" fill="none" />
                                    <path className={classes['checkmark__check']} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </React.Fragment>
                        }

                        {result == "fail" &&
                            <React.Fragment>
                                <svg className={`${classes['checkmark']} ${classes['checkmark--fail']}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className={`${classes['checkmark__circle']} ${classes['checkmark__circle--fail']}`} cx="26" cy="26" r="25" fill="none" />
                                    <path
                                        d="M 17,18 C 36,36 36,36 36,36"
                                        className={classes['mark-cross']}
                                    />
                                    <path
                                        d="M 17,36 C 36,18 36,18 36,18"
                                        className={classes['mark-cross']}
                                    />

                                </svg>
                            </React.Fragment>
                        }

                        </div>

                        {result =="success" &&
                            <div className={classes['result-message']}>
                                <span>Congratulation</span>
                                <br/>
                                <span>You have successfully activate PEGA AI</span>
                            </div>
                        }

                        {result =="fail" &&
                            <div className={classes['result-message']}>
                                <span>{msg ? msg : "Error. Fail to activate PEGA AI"}</span>
                            </div>
                        }

                </div>
            </div>
        </React.Fragment>
    )
}

const ClientActivation = (props) => {
    const {onCloseHandler, onSuccessHandler} = props
    const [modalResultData, dispatchModalResultData] = useReducer(modalReducer, defaultModalData)
    const [modalActivatationData, dispatchModalActivatationData] = useReducer(modalReducer, defaultModalData)

    const closeModalResultHandler = (result) => {
        dispatchModalResultData({type: "data", val: defaultModalData})
        if(result == "success"){
            onSuccessHandler()
        }
    }

    const closeModalActivationHandler = () => {
        onCloseHandler()
    }

    const showLicenseActivationResult = (result, msg) => {
        let newData = {
            body: <BodyResult result={result} msg={msg}/>,
            isOpenModal: true,
            mode: "plain",
            closeButton: true,
            onClose: (() => {closeModalResultHandler(result)}),
            modalWrapperClass: classes['modal-result'],
            modalBodyWrapperClass: classes['modal-result--body'],
            modalParentBodyWrapperClass: classes['modal-result--parent-body'],
            modalHeaderClass: classes['modal-result--header']
        }

        dispatchModalResultData({type: "data", val: newData})
        dispatchModalActivatationData({type: "data", val: {isProcessing: false}})

        //close modal input key when result is success
        if(result == "success"){
            dispatchModalActivatationData({type: "data", val: defaultModalData})
        }
    }

    useEffect(() => {
        let newData = {
            body: <BodyLicense onActivate={showLicenseActivationResult} dispatchModalActivatationData={dispatchModalActivatationData}/>,
            isOpenModal: true,
            title: "License Activation",
            mode: "plain",
            closeButton: true,
            onClose: closeModalActivationHandler,
            modalWrapperClass: classes['modal-activation'],
            modalBodyWrapperClass: classes['modal-activation--body'],
            modalParentBodyWrapperClass: classes['modal-activation--parent-body'],
            modalHeaderClass: classes['modal-activation--header']
        }

        dispatchModalActivatationData({type: "data", val: newData})

    }, [])

    return (
        <React.Fragment>

            {modalActivatationData.isOpenModal &&
                <Modal
                    {...modalActivatationData}
                />
            }

            {modalResultData.isOpenModal &&

                <Modal
                    {...modalResultData}
                />
            }
        </React.Fragment>

    )
}

export default ClientActivation