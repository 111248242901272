import { createSlice } from '@reduxjs/toolkit';
import { transferTimeFormat } from '../utils/iplas_utils';

const now = new Date();
now.setHours(now.getHours() + 1, 0, 0, 0);

const initialState = {
  activated: true,
  description: '',
  activeTime: transferTimeFormat(now, 'YYYY/MM/DD HH:00:00'),
  expiredTime: transferTimeFormat(now, 'YYYY/MM/DD HH:00:00'),
  cron: '0 0 * * *',
  receiver: [],
  input: {},
  expireTime: '',
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setActivated: (state, action) => {
      state.activated = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setActiveTime: (state, action) => {
      state.activeTime = action.payload;
    },
    setExpiredTime: (state, action) => {
      state.expiredTime = action.payload;
    },
    setCron: (state, action) => {
      state.cron = action.payload;
    },
    setReceiver: (state, action) => {
      state.receiver = action.payload;
    },
    setInput: (state, action) => {
      state.input = { ...state.input, ...action.payload };
    },
    initScheduleState: (state, action) => {
      return initialState;
    },
    setExpireTime: (state, action) => {
      state.expireTime = action.payload;
    },
  },
});

export const {
  setActivated,
  setDescription,
  setActiveTime,
  setExpiredTime,
  setCron,
  setInput,
  setReceiver,
  initScheduleState,
  setExpireTime,
} = scheduleSlice.actions;
export default scheduleSlice.reducer;
