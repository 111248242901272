import { ServerAPIPrefix } from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';

export const httpGetPlatformInfos = async (type = 'all') => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}platform/info?type=${type}`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpAddPlatformFeedback = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}platform/feedback`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      subject: payload.subject,
      content: payload.content,
      topic: payload.topic,
      user: payload.user,
    }),
  });
  return returnResponse(response);
};

export const httpGetInvitationCode = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}platform/invitation_code`;
  const response = await fetch(url, { ...options });
  return await response.json();
};

export const httpVerifyInvitationCode = async (code) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}platform/invitation_code?code=${code}`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
  });
  return await response.json();
};

export const httpGetPlatformFeedback = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}platform/feedback`;
  const response = await fetch(url, { ...options });
  return await response.json();
};

export const httpSetFeedbackEmail = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}platform/feedback/emails`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      emails: payload.emails,
    }),
  });
  return returnResponse(response);
};
