import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterField: '',
  filterValue: '',
};

const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilterField: (state, action) => {
      state.filterField = action.payload;
    },
    setFilterValue: (state, action) => {
      state.filterValue = action.payload;
    },
    initFilterState: (state, action) => {
      return initialState;
    },
  },
});

export const { setFilterField, setFilterValue, initFilterState } =
  FilterSlice.actions;
export default FilterSlice.reducer;
