import {
  httpGetTasksWithStatusByIds,
  httpGetReleaseBuildsNew,
  httpGetReleaseBuildsWithStatusByIds,
  httpGetTaskAndWeights,
  httpReleaseTask,
  httpGetSubmodels,
  httpChangeWeightStatus,
  httpGetTaskArtifact,
  httpGetReleaseArtifact,
  httpGetReleaseBuildsWithStatus,
} from '../models_utils';
import { httpUpdateModelQuality } from '../utils/deploy_utils';

import { getDefaultOptions, returnResponse } from './apiConfig';

import { ServerAPIPrefix, ServerURL } from '../config/config';

export const httpEarlyStopTask = async (param) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}models/${param.token}/tasks/${param.taskid}/earlystop?_submodel=${param.submodel}`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
  });
  return returnResponse(response);
};

export const httpSyncProjectTrainTasks = async (param) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}models/${param.modelToken}/tasks`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
  });
  return returnResponse(response);
};

export const httpResumeTask = async (param) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}pipelineProxy/experiment/${param.taskid}/resume`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
  });
  return returnResponse(response);
};

export const httpCancelTask = async (param) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}models/${param.token}/tasks/${param.taskid}/cancel?_submodel=${param.submodel}&_user=${param.username}`;
  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
  });
  return returnResponse(response);
};

export const httpDeleteTask = async (param) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}models/${param.token}/tasks/${param.taskid}?_user=${param.username}`;
  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
    body: JSON.stringify(param.data),
  });
  return returnResponse(response);
};
export const httpGetTaskConfigInfo = async (param) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}pipelineProxy/experiment/${param}/config`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpGetWeightInfo = async (param) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}pipelineProxy/experiment/${param.value}/weights`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpGetDataset = async (param) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}models/${param.modelToken}/dataset?_visible=${param.visible}`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const getTrainTasks = (token, submodel, sync) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => resolve(response),
      error: (jqXHR) => reject(jqXHR),
    };
    httpGetTaskAndWeights(token, submodel, sync, handlers);
  });
};

export const getTrainTaskByTaskIds = (token, submodel, payload) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => resolve(response),
      error: (jqXHR) => reject(jqXHR),
    };
    httpGetTasksWithStatusByIds(null, token, submodel, payload, handlers);
  });
};

export const getReleaseBuilds = (token, submodel) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => resolve(response),
      error: (jqXHR) => reject(jqXHR),
    };
    httpGetReleaseBuildsNew(token, submodel, handlers);
  });
};

export const getReleaseBuildsAndSync = (token, submodel) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => resolve(response),
      error: (jqXHR) => reject(jqXHR),
    };
    httpGetReleaseBuildsWithStatus(null, token, '', submodel, handlers);
  });
};

export const getReleaseBuildsByTaskIds = (token, submodel, payload) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => resolve(response),
      error: (jqXHR) => reject(jqXHR),
    };
    httpGetReleaseBuildsWithStatusByIds(
      null,
      token,
      submodel,
      payload,
      handlers,
    );
  });
};

export const getTaskEvalData = async (token, experimentIds) => {
  const options = getDefaultOptions();

  const url = `${ServerAPIPrefix}pipelineProxy/modelboard/experiment/performance/get`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(experimentIds),
  });
  return returnResponse(response);
};

export const releaseTask = (
  task,
  experimentId,
  weight,
  deploy,
  payload,
  entryType,
  username,
) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => resolve(response),
      error: (jqXHR) => reject(jqXHR),
    };
    httpReleaseTask(
      null,
      task.ModelToken,
      experimentId,
      weight,
      deploy,
      payload,
      entryType,
      username,
      handlers,
    );
  });
};

export const getModelConfig = (projectToken, modelname, modelversion) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => resolve(response),
      error: (jqXHR) => reject(jqXHR),
    };
    httpGetSubmodels(null, projectToken, modelname, modelversion, handlers);
  });
};

export const getMaxUserGPUNum = async (token, payload) => {
  const options = getDefaultOptions();

  const url =
    ServerURL + `/api/v1/resourceProxy/gpus/group_quota_by_tokens/get`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return returnResponse(response);
};

export const deployToCloud = (payload) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => resolve(response),
      error: (jqXHR) => reject(jqXHR),
    };
    httpChangeWeightStatus(this, payload, handlers);
  });
};

export const deployToMachine = (payload) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => resolve(response),
      error: (jqXHR) => reject(jqXHR),
    };
    httpUpdateModelQuality(this, payload, handlers);
  });
};

export const getTaskTrainLog = (token, taskId) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (data, status, xhr) => resolve({ data, status, xhr }),
      error: (jqXHR) => reject(jqXHR),
    };
    httpGetTaskArtifact(null, token, taskId, 'pipelineLogs', handlers);
  });
};

export const getTaskReleaseLog = (token, taskId, buildId) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (data, status, xhr) => resolve({ data, status, xhr }),
      error: (jqXHR) => reject(jqXHR),
    };
    httpGetReleaseArtifact(
      null,
      token,
      taskId,
      buildId,
      'pipelineLogs',
      handlers,
    );
  });
};
