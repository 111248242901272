import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  official: false,
  manualInput: {},
  manualErrorList: [],
  scheduleInput: {},
  scheduleErrorList: [],
};

const customInputSlice = createSlice({
  name: 'customInput',
  initialState,
  reducers: {
    setOfficial: (state, action) => {
      state.official = action.payload;
    },
    setDefaultInput: (state, action) => {
      state.manualInput = { ...state.manualInput, ...action.payload };
      state.scheduleInput = { ...state.scheduleInput, ...action.payload };
    },
    setInput: (state, action) => {
      const { type, key, value, queryType } = action.payload;
      if (queryType === 'Manual') {
        if (type === 'str') {
          state.manualInput = { ...state.manualInput, [key]: value };
        } else {
          state.manualInput = { ...state.manualInput, [key]: +value };
        }
      } else {
        if (type === 'str') {
          state.scheduleInput = { ...state.scheduleInput, [key]: value };
        } else {
          state.scheduleInput = { ...state.scheduleInput, [key]: +value };
        }
      }
    },
    validateInput: (state, action) => {
      const { key, isError, queryType } = action.payload;
      if (isError) {
        if (queryType === 'Manual') {
          const index = state.manualErrorList.indexOf(key);
          if (index === -1) {
            state.manualErrorList.push(key);
          }
        } else {
          const index = state.scheduleErrorList.indexOf(key);
          if (index === -1) {
            state.scheduleErrorList.push(key);
          }
        }
      } else {
        if (queryType === 'Manual') {
          state.manualErrorList = state.manualErrorList.filter(
            (item) => item !== key,
          );
        } else {
          state.scheduleErrorList = state.scheduleErrorList.filter(
            (item) => item !== key,
          );
        }
      }
    },
    setErrorList: (state, action) => {
      const { queryType, errorList } = action.payload;
      if (queryType === 'Manual') {
        state.manualErrorList = errorList;
      } else {
        state.scheduleErrorList = errorList;
      }
    },
    initCustomInputState: (state, action) => {
      return initialState;
    },
  },
});

export const {
  setOfficial,
  setDefaultInput,
  setInput,
  validateInput,
  setErrorList,
  initCustomInputState,
} = customInputSlice.actions;
export default customInputSlice.reducer;
