import { useReducer, useCallback } from 'react';

function httpReducer(state, action) {
  if (action.type === 'SEND') {
    return {
      data: null,
      error: null,
      status: 'sending',
      isFetching: true,
      param: action.requestData,
    };
  }

  if (action.type === 'SUCCESS') {
    return {
      data: action.responseData,
      error: null,
      status: 'success',
      isFetching: false,
      param: action.requestData,
    };
  }

  if (action.type === 'ERROR') {
    return {
      data: null,
      error: action.errorMessage,
      status: 'error',
      isFetching: false,
      param: action.requestData,
    };
  }

  return state;
}

function useHttp(requestFunction) {
  const [httpState, dispatch] = useReducer(httpReducer, {
    data: null,
    error: null,
    status: null,
    param: null,
    isFetching: false,
  });

  const sendRequest = useCallback(
    async function (requestData) {
      dispatch({ type: 'SEND', requestData });

      try {
        const responseData = await requestFunction(requestData);
        dispatch({ type: 'SUCCESS', responseData, requestData });
      } catch (error) {
        console.log(error);
        dispatch({
          type: 'ERROR',
          errorMessage: error.message || 'Something went wrong',
          requestData,
        });
      }
    },
    [requestFunction],
  );

  return {
    sendRequest,
    ...httpState,
  };
}

export default useHttp;
