import { createSlice } from '@reduxjs/toolkit';
// import { ALL_ITEM } from '../../CommonVariables';

const initialState = {
  jobHistoryList: {
    count: 0,
    data: [],
  },
  jobName: 'All',
  type: 'Manual',
};

const JobHistorySlice = createSlice({
  name: 'jobHistory',
  initialState,
  reducers: {
    setJobHistoryList: (state, action) => {
      state.jobHistoryList = action.payload;
    },
    setJobName: (state, action) => {
      state.jobName = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    initJobHistoryState: (state, action) => {
      return initialState;
    },
  },
});

export const { setJobHistoryList, setJobName, setType, initJobHistoryState } =
  JobHistorySlice.actions;
export default JobHistorySlice.reducer;
