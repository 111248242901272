import { ServerAPIPrefix } from '../config/config';

function httpGetPredictMachine(context, handlers) {
  const settings = {
    url: ServerAPIPrefix + 'deploy',
    type: 'GET',
    context,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpGetPredictMachineList(context, owner, handlers) {
  const settings = {
    url: ServerAPIPrefix + `deploy/list?owner=${owner}`,
    type: 'GET',
    context,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpPostAvaliableMachineList(context, data, handlers) {
  const settings = {
    url: ServerAPIPrefix + `deploy/avaliable`,
    type: 'POST',
    context,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpCreateToken(context, owner, deployCount, site, handlers) {
  const settings = {
    url:
      ServerAPIPrefix +
      `deploy/token?owner=${owner}&deploy_count=${deployCount}&site=${site}`,
    type: 'PUT',
    context,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpCreatePredictMachine(context, data, handlers) {
  const settings = {
    url: ServerAPIPrefix + 'deploy',
    type: 'POST',
    contentType: 'application/json',
    context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpTriggerDeployment(context, data, handlers) {
  const settings = {
    url: ServerAPIPrefix + 'deploy/trigger',
    type: 'POST',
    contentType: 'application/json',
    context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpUpdateModelQuality(context, data, handlers) {
  const settings = {
    url: ServerAPIPrefix + 'deploy',
    type: 'POST',
    contentType: 'application/json',
    context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

export {
  httpCreateToken,
  httpGetPredictMachine,
  httpGetPredictMachineList,
  httpCreatePredictMachine,
  httpTriggerDeployment,
  httpUpdateModelQuality,
  httpPostAvaliableMachineList,
};
