import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  showPlatformWarning: false,
};

const slice = createSlice({
  name: 'platformWarning',
  initialState,
  reducers: {
    setShowPlatformWarning: (state, action) => {
      state.showPlatformWarning = action.payload;
    },
  },
});

export const { setShowPlatformWarning } = slice.actions;

export default slice.reducer;
