import { getCookie } from '../common';
import { ServerAPIPrefix } from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';

const licenseApiPrefix = `${ServerAPIPrefix}licenseProxy/`;
const clientLicenseApiPrefix = `${ServerAPIPrefix}cambrianServiceProxy/`;

const modifiedOptions = () => {
  const options = getDefaultOptions();
  options.headers.owner = atob(getCookie('user'));

  return options;
};

export const httpGetLicense = async (payload) => {
  const options = modifiedOptions();
  const url = `${licenseApiPrefix}license?subscription_token=${payload.subscriptionToken}`;
  const response = await fetch(url, { ...options });

  return returnResponse(response);
};

export const httpGetActivation = async (payload) => {
  const options = modifiedOptions();
  const url = `${licenseApiPrefix}activation?license_id=${payload.licenseId}`;
  const response = await fetch(url, { ...options });

  return returnResponse(response);
};

export const httpOfflineActivate = async (payload) => {
  const options = modifiedOptions();
  delete options.headers['Content-Type'];

  const url = `${licenseApiPrefix}activation/offline-activate?license_id=${payload.licenseId}`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: payload.data,
  });

  return returnResponse(response, true);
};

export const httpSetClientLicenseKey = async (payload) => {
  const options = modifiedOptions();
  const url = `${clientLicenseApiPrefix}license/set_license_key`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      key: payload.key,
    }),
  });

  return returnResponse(response, true);
};

export const httpCreateLicenseRequest = async (payload) => {
  const options = modifiedOptions();
  const url = `${clientLicenseApiPrefix}license/create_license_request`;
  const response = await fetch(url, { ...options });

  return returnResponse(response, true);
};

export const httpClientOfflineActivate = async (payload) => {
  const options = modifiedOptions();
  delete options.headers['Content-Type'];

  const url = `${clientLicenseApiPrefix}license/set_license_file`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: payload.data,
  });

  return returnResponse(response, true);
};
