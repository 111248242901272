import { ServerAPIPrefix } from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';

const filesApiPrefix = `${ServerAPIPrefix}fileservice/`;

export const httpGetFilesByToken = async (param) => {
  const options = getDefaultOptions();
  const { fileListUrl, payload } = param;
  const url = `${filesApiPrefix}${fileListUrl}/files_by_tokens`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      ownerId: payload.projectToken,
      file_tokens: payload.fileTokens,
    }),
  });

  return returnResponse(response);
};

export const httpGetPromotionalVideosInfo = async (param) => {
  const options = getDefaultOptions();
  const url = `file/cambrian/promotional_videos/promotional_videos_config.json`;
  const response = await fetch(url, {
    ...options,
    method: 'GET',
  });

  return returnResponse(response);
};
