import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { createDispatchHook, createSelectorHook } from 'react-redux';
import modelTrainBoardReducer from './modelTrainBoardSlice';
import solutionReducer from './solutionSlice';
import chatReducer from './chatSlice';
import trainReducer from './trainSlice';
import importDatasetReducer from './importDatasetSlice';
import deviceInfoReducer from './deviceInfoSlice';
import smtRejudgeReducer from './smtRejudgeSlice';
import tableSortReducer from './tableSortSlice';
import smtMultiSearchReducer from './smtMultiSearchSlice';
import jobInputReducer from './jobInputSlice';
import customInputReducer from './CustomInputSlice';
import loadingReducer from './loadingSlice';
import scheduleReducer from './scheduleSlice';
import columnSortReducer from './columnSortSlice';
import jobHistoryReducer from './jobHistorySlice';
import filterReducer from './FilterSlice';
import pegaverseReducer from './pegaverseSlice';
import audioReducer from './audioSlice';

const store = configureStore({
  reducer: {
    modeltrainboard: modelTrainBoardReducer,
    solution: solutionReducer,
    chat: chatReducer,
    train: trainReducer,
    deviceInfo: deviceInfoReducer,
    importDataset: importDatasetReducer,
    smtRejudge: smtRejudgeReducer,
    smtMultiSearch: smtMultiSearchReducer,
    tableSort: tableSortReducer,
    jobInput: jobInputReducer,
    customInput: customInputReducer,
    loading: loadingReducer,
    schedule: scheduleReducer,
    columnSort: columnSortReducer,
    jobHistory: jobHistoryReducer,
    filter: filterReducer,
    pegaverse: pegaverseReducer,
    audio: audioReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;

export const storeContext = React.createContext();
export const useStoreDispatch = createDispatchHook(storeContext);
export const useStoreSelector = createSelectorHook(storeContext);
