import { getSystemConfig } from '../utils/common_utils';
import { apiOptions, api_options_delete } from './apiOptions';
import { promiseCheckClientLicense } from './licenseClient';
import { LicenseErrorCode } from '../Platform/LicenseWarning';

export const getDefaultOptions = () => {
  return apiOptions();
};

export const deleteDefaultOptions = () => {
  return api_options_delete();
};

export const returnResponse = async (response, includeResponseCode = false) => {
  /*
        includeResponseCode = boolean. This is to indicate the return response should return the message only or with the return code to UI. Default is false
    */

  checkLicense(response);

  if (response.status === 401) {
    console.log('401 Unauthorized');
    window.location.href = '/login';
    return;
  }

  if (!response.ok) {
    let err = null;
    try {
      const resultData = await response.json();

      if (includeResponseCode) {
        err = resultData;
      } else {
        if (resultData?.errors?.error) {
          err = resultData.errors.error;
        } else if (resultData?.errorMsg) {
          err = resultData.errorMsg;
        } else if (resultData?.err_msg) {
          err = resultData.err_msg;
        } else {
          console.log(resultData);
          err = resultData.message
            ? resultData.message
            : resultData.detail
              ? resultData.detail
              : response.statusText
                ? response.status + ' ' + response.statusText
                : response.status;
        }
      }
    } catch (e) {
      console.log(e);
      console.log(response);
      err = response.statusText
        ? response.status + ' ' + response.statusText
        : response.status;
    }

    if (!includeResponseCode) {
      try {
        if (typeof err !== 'string') err = JSON.stringify(err);
      } catch (e) {
        err = err;
      }

      throw new Error(err);
    } else {
      throw new Error(JSON.stringify({ status: response.status, error: err }));
    }
  }

  if (response.status === 204) {
    return response;
  } else {
    if (includeResponseCode) {
      // need own parser
      return response;
    }
    try {
      const resultData = await response.json();
      return resultData;
    } catch (e) {
      if (response.status >= 200 && response.status < 300) {
        return 'OK';
      } else {
        console.log(response);
        throw new Error('Parsing response error. Invalid JSON');
      }
    }
  }
};

const checkLicense = (response) => {
  if (getSystemConfig('REQUIRED_LICENSE')) {
    if (
      Object.keys(LicenseErrorCode).indexOf(response.status.toString()) > -1 &&
      response.status.toString() !== '500'
    ) {
      promiseCheckClientLicense();
    }
  }
};
