import { ServerURL } from '../config/config';
import {
  api_options_delete_json,
  api_options_get,
  api_options_post_json,
  api_options_put_json,
} from './apiOptions';
import { returnResponse } from './apiConfig';

export const http_get_personal_access_token = async () => {
  const url = `${ServerURL}/api/v1/p/auth/api/v1/personal_access_token`;
  const response = await fetch(url, { ...api_options_get() });

  return returnResponse(response);
};

export const http_post_personal_access_token = async (
  token_name,
  token_description,
  expired_date_timestamp,
) => {
  const url = `${ServerURL}/api/v1/p/auth/api/v1/personal_access_token`;
  const json_dict = {
    token_name,
    token_description,
    expire_date: expired_date_timestamp,
  };

  const response = await fetch(url, {
    ...api_options_post_json(),
    body: JSON.stringify(json_dict),
  });

  return returnResponse(response);
};

export const http_delete_personal_access_token = async (token_name) => {
  const url = `${ServerURL}/api/v1/p/auth/api/v1/personal_access_token`;
  const json_dict = {
    token_name,
  };

  const response = await fetch(url, {
    ...api_options_delete_json(),
    body: JSON.stringify(json_dict),
  });

  return returnResponse(response);
};

export const http_get_ldap_config = async () => {
  const url = `${ServerURL}/api/v1/p/auth/api/v1/ldap_configuration?group=cambrian`;
  const response = await fetch(url, { ...api_options_get() });

  return returnResponse(response);
};

export const http_put_ldap_config = async (
  ldap_url = '',
  ldap_port = '',
  search_dn = '',
  search_pwd = '',
  base_search = '',
  uid = '',
  filter = '',
  is_enable = false,
) => {
  const url = `${ServerURL}/api/v1/p/auth/api/v1/ldap_configuration`;
  const json_dict = {
    group: 'cambrian',
    enable: is_enable,
    url: ldap_url,
    port: ldap_port,
    search_dn,
    search_pwd,
    base_search,
    uid,
    filter,
  };

  const response = await fetch(url, {
    ...api_options_put_json(),
    body: JSON.stringify(json_dict),
  });

  return returnResponse(response);
};

export const http_post_ldap_config = async (
  ldap_url = '',
  ldap_port = '',
  search_dn = '',
  search_pwd = '',
  base_search = '',
  uid = '',
  filter = '',
) => {
  const url = `${ServerURL}/api/v1/p/auth/api/v1/ldap_configuration`;
  const json_dict = {
    group: 'cambrian',
    url: ldap_url,
    port: ldap_port,
    search_dn,
    search_pwd,
    base_search,
    uid,
    filter,
  };

  const response = await fetch(url, {
    ...api_options_post_json(),
    body: JSON.stringify(json_dict),
  });

  return returnResponse(response);
};


export const http_post_query_ldap_user_info = async (username_list) => {
    const url = `${ServerURL}/api/v1/p/auth/api/v1/user_ldap_meta`;
    const json_dict = {
        user_list: username_list,
    }

    const response = await fetch(url, {
        ...api_options_post_json(),
        body: JSON.stringify(json_dict)
    });

    return returnResponse(response);
}

export const http_get_password_forget_trigger = async (email) => {
    const url = `${ServerURL}/api/v1/users/password_forget?email=` + email;
    const response = await fetch(url, { ...api_options_get() });

    return returnResponse(response);
}

export const http_post_password_forget_apply = async (email, captcha, password) => {
    const url = `${ServerURL}/api/v1/users/password_forget`;
    const json_dict = {
        email: email,
        captcha: captcha,
        password: password,
    }

    const response = await fetch(url, {
        ...api_options_post_json(),
        body: JSON.stringify(json_dict)
    });

    return returnResponse(response);
}

export const httpGetUserList = async ({ group, option }) => {
  const url = `${ServerURL}/api/v1/p/auth/api/v1/user_list`;

  const payload = {
    group,
    option,
  };

  const response = await fetch(url, {
    ...api_options_post_json(),
    body: JSON.stringify(payload),
  });

  return returnResponse(response);
};

export const httpCreateInvitedUsers = async ({
  username,
  invitation_code,
  role,
  group,
  user_scope,
}) => {
  const url = `${ServerURL}/api/v1/invite_user`;

  const payload = {
    username,
    invitation_code,
    role,
    group,
    user_scope,
  };

  const response = await fetch(url, {
    ...api_options_post_json(),
    body: JSON.stringify(payload),
  });

  return returnResponse(response);
};
