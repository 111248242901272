import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkIsGuest, getCookie } from '../common';
import { getSystemConfig } from '../utils/common_utils';
import toastr from 'toastr';
import { httpCheckPermission } from '../api/aiAssistantManagement';

const NAVTYPE = {
    SOLUTION: "/solutions",
    WORKSPACE: '/projects',
    DATASETS: "/datasets",
    EDGE_DATASETS: "/edge/datasets",
    DEPLOY: '/deploy',
    DIGITAL_ASSISTANT: '/digitalassistant/ai_assistant',
    PLAYGROUND: '/playground',
    EDGE_MODELS: "/edge/models",
    PREDICT_SERVING: "/edge/serving"
}


const getClass = (pathname, navType) => {
    if (pathname.startsWith( navType)) {
        return "nav-link navbar__item font--gold btn"
    }
    return "nav-link navbar__item font--white btn";
}

const NavBarContent = (props) => {
    const { t } = useTranslation("translation");
    const {location} = props

    const isHomePage = location.pathname==="/";

    if(getSystemConfig("DEPLOYMENT_TYPE") == "edge"){
        return(
            <React.Fragment>

                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link to={NAVTYPE.SOLUTION} className={getClass(location.pathname, NAVTYPE.SOLUTION)}>
                            {t('solution.solutions_b')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={NAVTYPE.EDGE_MODELS} className={getClass(location.pathname, NAVTYPE.EDGE_MODELS)}>
                            MODELS
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={NAVTYPE.PREDICT_SERVING} className={getClass(location.pathname, NAVTYPE.PREDICT_SERVING)}>
                            PREDICT SERVICE
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={NAVTYPE.EDGE_DATASETS} className={getClass(location.pathname, NAVTYPE.EDGE_DATASETS)}>
                            {t('dataset.datasets_b')}
                        </Link>
                    </li>
                </ul>
            </React.Fragment>
        )
    } else if(!isHomePage && !checkIsGuest()){

        return(
            <React.Fragment>

                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link to={NAVTYPE.SOLUTION} className={getClass(location.pathname, NAVTYPE.SOLUTION)}>
                            {t('solution.solutions_b')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={NAVTYPE.WORKSPACE} className={getClass(location.pathname, NAVTYPE.WORKSPACE)}>
                            {t('workspace.workspace_b')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={NAVTYPE.DATASETS} className={getClass(location.pathname, NAVTYPE.DATASETS)}>
                            {t('dataset.datasets_b')}
                        </Link>
                    </li>
                    {
                        window.ENABLE_DEPLOY &&
                        <li className="nav-item">
                            <Link to={NAVTYPE.DEPLOY} className={getClass(location.pathname, NAVTYPE.DEPLOY)}>
                                {t('deploy.deploy_b')}
                            </Link>
                        </li>
                    }
                </ul>
            </React.Fragment>
        )
    }
}

function NavbarList() {
    const location = useLocation();
    const { t } = useTranslation("translation");
    const navigate = useNavigate();
    const goToChat = async () => {
        try {
          const response = await httpCheckPermission();
          if (!response.result) {
            toastr.warning(
              t(
                'ai_assistant.check_failed_message',
              ),
            );
            return;
          }
          navigate(NAVTYPE.DIGITAL_ASSISTANT);
        } catch (error) {
          toastr.error(t('ai_assistant.check_error'));
        }
      };
    return (
        <nav className="navbar navbar-expand-md navbar-dark" style={{padding:'5px 0'}}>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <NavBarContent location={location} />
                </div>
                {window.ENABLE_CHAT && window.ENABLE_CHAT.toString().toLowerCase() == "true" &&
                    <span className={getClass(location.pathname, NAVTYPE.DIGITAL_ASSISTANT)} state={{prev_URL: window.location.href}} onClick={goToChat}>
                        {t('ai_assistant.ai_assistant_b')}
                    </span>
                }

                {window.ENABLE_PLAYGROUND && window.ENABLE_PLAYGROUND.toString().toLowerCase() == "true" &&
                    <Link to={NAVTYPE.PLAYGROUND} className={getClass(location.pathname, NAVTYPE.PLAYGROUND)}>
                        {t('playground.playground_b')}
                    </Link>
                }
            </nav>
    );
};


function SecondBar(props) {

    const secondBarStyle = {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    }

    const toolBarStyle = {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    }

    return (
        <div style={{ height:"70px", width:'100%'}}>
            <div className="width80 col-centered" style={secondBarStyle}>
                <div style={toolBarStyle}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export {
    NavbarList,
    SecondBar,
}
