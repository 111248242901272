import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  trainModelConfig: {},
};

const slice = createSlice({
  name: 'train',
  initialState,
  reducers: {
    setTrainModelConfig: (state, action) => {
      state.trainModelConfig = action.payload;
    },
  },
});

export const { setTrainModelConfig } = slice.actions;

export default slice.reducer;
