import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  httpGetDataset,
  getTrainTasks,
  getReleaseBuilds,
  getMaxUserGPUNum,
  getModelConfig,
  deployToCloud,
  deployToMachine,
  releaseTask,
  getReleaseBuildsByTaskIds,
  getTrainTaskByTaskIds,
  getTaskEvalData,
  httpCancelTask,
  getReleaseBuildsAndSync,
} from '../api/modelBoard';

export const fetchDataset = createAsyncThunk(
  'modeltrainboard/fetchDataset',
  async (payload, thunkApi) => {
    const { modelToken, visible } = payload;
    const response = await httpGetDataset({ modelToken, visible });
    return response;
  },
);

export const fetchTrainTasks = createAsyncThunk(
  'modeltrainboard/fetchTrainTasks',
  async (payload, thunkApi) => {
    const { token, submodel, sync } = payload;
    const response = await getTrainTasks(token, submodel, sync);
    return response;
  },
);

export const fetchTrainTasksByExpIds = createAsyncThunk(
  'modeltrainboard/fetchTrainTasksByExpIds',
  async (payload, { rejectWithValue }) => {
    try {
      const { token, submodel, expIds } = payload;
      const response = await getTrainTaskByTaskIds(token, submodel, expIds);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchReleaseBuilds = createAsyncThunk(
  'modeltrainboard/fetchReleaseTask',
  async (payload, thunkApi) => {
    const { token, submodel } = payload;
    const response = await getReleaseBuilds(token, submodel);
    return response;
  },
);

export const fetchReleaseBuildsAndSync = createAsyncThunk(
  'modeltrainboard/fetchReleaseBuildsAndSync',
  async (payload, { rejectWithValue }) => {
    try {
      const { token, submodel } = payload;
      const response = await getReleaseBuildsAndSync(token, submodel);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchReleaseBuildsByExpIds = createAsyncThunk(
  'modeltrainboard/fetchReleaseBuildsByExpIds',
  async (payload, { rejectWithValue }) => {
    try {
      const { token, submodel, expIds } = payload; // expIds = {experiments: [ids]}
      const response = await getReleaseBuildsByTaskIds(token, submodel, expIds);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const cancelReleaseBuilds = createAsyncThunk(
  'modeltrainboard/cancelReleaseBuilds',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await httpCancelTask(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchTaskEvalData = createAsyncThunk(
  'modeltrainboard/fetchTaskEvalData',
  async (payload, { rejectWithValue }) => {
    try {
      const { token, expIds } = payload;
      const response = await getTaskEvalData(token, expIds);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchModelConfig = createAsyncThunk(
  'modeltrainboard/fetchModelConfig',
  async (payload, thunkApi) => {
    const { token, submodel, version } = payload;
    const response = await getModelConfig(token, submodel, version);
    return response;
  },
);

export const fetchMaxUserGPUNum = createAsyncThunk(
  'modeltrainbaord/fetchMaxUserGPUNum',
  async (payload, { rejectWithValue }) => {
    const { token, data } = payload;
    try {
      const response = await getMaxUserGPUNum(token, data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deployTaskToCloud = createAsyncThunk(
  'modeltrainboard/deployTaskToCloud',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await deployToCloud(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deployTaskToMachine = createAsyncThunk(
  'modeltrainboard/deployTaskToMachine',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await deployToMachine(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const triggerReleaseTask = createAsyncThunk(
  'modeltrainboard/triggerReleaseTask',
  async (payload, { rejectWithValue }) => {
    const { task, experimentId, weight, deploy, data, entryType, username } =
      payload;
    try {
      const response = await releaseTask(
        task,
        experimentId,
        weight,
        deploy,
        data,
        entryType,
        username,
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const trainTaskEntityAdapter = createEntityAdapter({
  selectId: (task) => task.Name,
});

const releaseTaskEntityAdapter = createEntityAdapter({
  selectId: (release) => release.Task,
});

const trainTaskEvalAdapter = createEntityAdapter({
  selectId: (task) => task.experimentId,
});

const initialState = {
  datasets: [],
  modalActionData: {},
  showModalAction: false,
  loading: true,
  isFetchingTrainTask: false,
  isFetchingReleaseTask: false,
  isDeploying: {},
  isReleasing: {},
  isReleaseCanceling: {},
  project: null,
  entryType: '',
  otaTool: {},
  maxGPUNum: -1,
  defaultRuntime: 'GPU',
  permission: null,
  modelConfig: null,
  selectedModel: '',
  selectedStatus: '-1',
  selectedVersion: '-1',
  trainPollingList: [],
  groupResourceInfo: null,
  userMemberPermission: null,
  setOTAToolLink: () => {},
  onDeleted: () => {},
  onCanceled: () => {},
  trainTask: trainTaskEntityAdapter.getInitialState(),
  releaseTask: releaseTaskEntityAdapter.getInitialState(),
  taskEvalData: trainTaskEvalAdapter.getInitialState(),
};

const extraSlice = {
  // don't reset items below when initState
  projectList: [],
};

const slice = createSlice({
  name: 'modeltrainboard',
  initialState: { ...initialState, ...extraSlice },
  reducers: {
    initState(state) {
      return { ...initialState, ...state };
    },
    removeTask(state, action) {
      trainTaskEntityAdapter.removeOne(state.trainTask, action.payload);
    },
    setModalActionData(state, action) {
      state.modalActionData = action.payload;
    },
    setShowModalAction(state, action) {
      state.showModalAction = action.payload;
    },
    changeModel(state, action) {
      state.selectedModel = action.payload;
    },
    setSelectedModel(state, action) {
      state.selectedModel = action.payload;
    },
    setSelectedVersion(state, action) {
      state.selectedVersion = action.payload;
    },
    setSelectedStatus(state, action) {
      state.selectedStatus = action.payload;
    },
    setProject(state, action) {
      state.project = action.payload;
    },
    setOTATool(state, action) {
      const { value } = action.payload;
      state.otaTool = value;
    },
    initStateFromProps(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
    setIsDeploying(state, action) {
      const { experimentId, isDeploy } = action.payload;
      state.isDeploying[experimentId] = isDeploy;
    },
    setIsReleasing(state, action) {
      const { experimentId, isRelease } = action.payload;
      state.isReleasing[experimentId] = isRelease;
    },
    setIsReleaseCanceling(state, action) {
      const { experimentId, isReleaseCancel } = action.payload;
      state.isReleaseCanceling[experimentId] = isReleaseCancel;
    },
    setTrainPollingList(state, action) {
      state.trainPollingList = action.payload;
    },
    setMaxGPUNum(state, action) {
      state.maxGPUNum = action.payload;
    },
    setDefaultRuntime(state, action) {
      state.defaultRuntime = action.payload;
    },
    setProjectList(state, action) {
      state.projectList = action.payload;
    },
    setModelConfig(state, action) {
      state.modelConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataset.fulfilled, (state, action) => {
        state.datasets = action.payload;
      })
      .addCase(fetchTrainTasks.pending, (state) => {
        state.isFetchingTrainTask = true;
      })
      .addCase(fetchTrainTasks.fulfilled, (state, action) => {
        state.isFetchingTrainTask = false;
        if (action.meta.arg.submodel !== state.selectedModel) return;
        trainTaskEntityAdapter.setAll(state.trainTask, action.payload);
      })
      .addCase(fetchTrainTasks.rejected, (state, action) => {
        state.isFetchingTrainTask = false;
      })
      .addCase(fetchReleaseBuilds.fulfilled, (state, action) => {
        releaseTaskEntityAdapter.setAll(state.releaseTask, action.payload);
      })
      .addCase(fetchReleaseBuildsAndSync.pending, (state) => {
        state.isFetchingReleaseTask = true;
      })
      .addCase(fetchReleaseBuildsAndSync.fulfilled, (state, action) => {
        state.isFetchingReleaseTask = false;
        releaseTaskEntityAdapter.setAll(state.releaseTask, action.payload);
      })
      .addCase(fetchReleaseBuildsAndSync.rejected, (state) => {
        state.isFetchingReleaseTask = false;
      })
      .addCase(fetchTaskEvalData.fulfilled, (state, action) => {
        const evalData = Object.keys(action.payload).reduce((prev, current) => {
          if (action.payload[current].status === 'error') return prev;
          const evalData = {
            ...action.payload[current],
            experimentId: current,
          };
          return [...prev, evalData];
        }, []);
        trainTaskEvalAdapter.upsertMany(state.taskEvalData, evalData);
      })
      .addCase(fetchModelConfig.fulfilled, (state, action) => {
        if (state.selectedModel !== action.meta.arg.submodel) return;
        state.modelConfig = action.payload;
      })
      .addCase(deployTaskToCloud.fulfilled, (state, action) => {
        const id = action.meta.arg.experimentId;
        const weight =
          state.trainTask.entities[id]?.Weights[action.meta.arg.weight];
        if (weight) weight.quality = action.meta.arg.status;
      })
      .addCase(triggerReleaseTask.fulfilled, (state, action) => {
        releaseTaskEntityAdapter.upsertOne(state.releaseTask, action.payload);
      })
      .addCase(fetchReleaseBuildsByExpIds.fulfilled, (state, action) => {
        releaseTaskEntityAdapter.upsertMany(state.releaseTask, action.payload);
      })
      .addCase(fetchTrainTasksByExpIds.fulfilled, (state, action) => {
        trainTaskEntityAdapter.upsertMany(state.trainTask, action.payload);
      });
  },
});

export const {
  initState,
  removeTask,
  setModalActionData,
  setShowModalAction,
  changeModel,
  setSelectedModel,
  setSelectedStatus,
  setSelectedVersion,
  setProject,
  setOTATool,
  setIsLoading,
  setIsDeploying,
  setIsReleasing,
  setIsReleaseCanceling,
  initStateFromProps,
  setTrainPollingList,
  setReleasePollingList,
  setMaxGPUNum,
  setDefaultRuntime,
  setProjectList,
  setModelConfig,
} = slice.actions;

export const { selectAll: selectAllTrainTask } =
  trainTaskEntityAdapter.getSelectors(
    (state) => state.modeltrainboard.trainTask,
  );

export const { selectEntities: selectAllReleaseBuildEntities } =
  releaseTaskEntityAdapter.getSelectors(
    (state) => state.modeltrainboard.releaseTask,
  );

export default slice.reducer;
