import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  deviceEdgeInfo: {
    GPU_driver_ver: '',
    GPUs: [],
  },
};

const slice = createSlice({
  name: 'deviceInfo',
  initialState,
  reducers: {
    setDeviceEdgeInfo: (state, action) => {
      state.deviceEdgeInfo = action.payload;
    },
  },
});

export const { setDeviceEdgeInfo } = slice.actions;

export default slice.reducer;
