import React from 'react';
import { httpGetUser, httpSetUserUsages } from './user_utils';
import { httpGetLDAPSetting } from './utils/auth_utils';
import queryString from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getSystemConfig } from './utils/common_utils';
import { role_dict } from './backend/user/constant';

export function CopyToClipboard(copytext) {
  if (window.clipboardData) {
    // Internet Explorer
    window.clipboardData.setData('Text', copytext);
  } else {
    const tempInput = document.createElement('input');
    tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
    tempInput.value = copytext;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }
}

export function downloadURI(uri) {
  const link = document.createElement('a');
  link.setAttribute('download', '');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  // document.body.removeChild(link);
}

export function downloadBlobFile(filename, data) {
  const url = URL.createObjectURL(data);
  const downloadAnchorNode = document.createElement('a');

  downloadAnchorNode.href = url;
  downloadAnchorNode.download = filename;
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  URL.revokeObjectURL(url);
  downloadAnchorNode.remove();
}

export function formatBytes(bytes, decimals, kilo) {
  if (isNaN(bytes)) return '0B';
  const k = kilo || 1024;
  const dm = decimals == 0 ? 0 : decimals + 1 || 2;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function secondsToDhms(sec) {
  sec = Number(sec);
  const noTime = '0"';
  if (sec == 0) return noTime;
  const d = Math.floor(sec / 86400);
  const h = Math.floor((sec % 86400) / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = Math.floor((sec % 3600) % 60);

  if (d == 0 && h == 0 && m == 0 && s == 0) return noTime;

  const dDisplay = d > 0 ? d + (d == 1 ? 'd' : 'd') : '';
  const hDisplay = h > 0 ? h + (h == 1 ? "'" : "'") : '';
  const mDisplay = m > 0 ? m + (m == 1 ? '"' : '"') : '';
  const sDisplay = s > 0 ? s + (s == 1 ? '"' : '"') : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function get_duration_ago(sec) {
  if (sec < 0) {
    return { time_value: '', time_unit: 'now' };
  }
  const MIN = 60;
  const HOUR = 60 * MIN;
  const DAY = 24 * HOUR;
  const MON = 30 * DAY;

  const mon = Math.floor(sec / MON);
  if (mon) return { time_value: mon, time_unit: 'months_ago' };

  const day = Math.floor(sec / DAY);
  if (day) return { time_value: day, time_unit: 'days_ago' };

  const hour = Math.floor(sec / HOUR);
  if (hour) return { time_value: hour, time_unit: 'hours_ago' };

  const min = Math.floor(sec / MIN);
  if (min) return { time_value: min, time_unit: 'mins_ago' };

  return { time_value: '', time_unit: 'now' };
}

export function get_local_time_str(date) {
  const date_str = date
    .toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    })
    .replace(/[/]/g, '-');

  return (
    date_str.slice(6, 10) + '-' + date_str.slice(0, 5) + date_str.slice(11)
  );
}

export function removeArrElem(arr, elem) {
  const index = arr.indexOf(elem);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function checkIsAdmin() {
  /* var user_info_data = getCookie("user_info");
    if (user_info_data && user_info_data.length > 0) {
        var user_info = JSON.parse(atob(user_info_data));
        if (user_info && user_info.role.toLowerCase() == "admin")
            return true;
    } */
  const user_info_data = getCookie('original_user_info');
  if (user_info_data && user_info_data.length > 0) {
    const user_info = JSON.parse(fromBinary(atob(user_info_data)));
    if (user_info && user_info.role.toLowerCase() == role_dict.admin)
      return true;
  }
  return false;
}

export function checkIsGuest() {
  const user_info_data = getCookie('original_user_info');
  if (user_info_data && user_info_data.length > 0) {
    const user_info = JSON.parse(fromBinary(atob(user_info_data)));
    if (user_info && user_info.role.toLowerCase() == role_dict.guest) {
      return true;
    }
  }
  return false;
}

export function checkIsPowerUser() {
  const user_info_data = getCookie('original_user_info');
  if (user_info_data && user_info_data.length > 0) {
    const user_info = JSON.parse(fromBinary(atob(user_info_data)));
    if (user_info && user_info.role.toLowerCase() == role_dict.power_user)
      return true;
  }
  return false;
}

export function checkIsStandardAccount() {
  const user_info_data = getCookie('original_user_info');
  if (user_info_data) {
    const user_info = JSON.parse(fromBinary(atob(user_info_data)));
    return user_info && user_info.ldap === false;
  }
}

export function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export function getCookie(name) {
  function escape(s) {
    return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1');
  }
  const match = document.cookie.match(
    RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'),
  );
  return match ? match[1] : null;
}

export function createCookie(name, value) {
  const hostname = window.location.hostname;
  const expire_days = 720; // hour
  const d = new Date();
  d.setTime(d.getTime() + expire_days * 60 * 60 * 1000);
  const expires = 'expires=' + d.toGMTString();
  // document.cookie = name + "=" + value + "; " + expires + "; domain=." + hostname + "; path=/";
  document.cookie =
    name + '=' + value + '; ' + expires + '; domain=' + hostname + '; path=/';
}

export function deleteAllCookie() {
  const theCookies = document.cookie.split(';');
  const hostname = window.location.hostname;
  let aString = '';
  for (let i = 0; i < theCookies.length; i++) {
    aString = theCookies[i];
    // document.cookie = aString + ';domain=.' + hostname + '; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC';
    // document.cookie = aString + ';domain=' + hostname + '; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC';
    deleteCookie(aString.split('=')[0]);
  }
}

export function deleteCookie(name) {
  const hostname = window.location.hostname;
  const cookie_date = new Date();
  cookie_date.setTime(cookie_date.getTime() - 1);
  document.cookie =
    name +
    '=;domain=' +
    hostname +
    '; path=/;expires=' +
    cookie_date.toGMTString();
  document.cookie = name + '=; expires=' + cookie_date.toGMTString();
  /* function escape(s) { return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1'); };
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    if (match && match.length > 0) {
        var hostname = window.location.hostname;
        document.cookie = name + "=" + match + ';domain=.' + hostname + '; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC';
        document.cookie = name + "=" + match + ';domain=' + hostname + '; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC';
    } */
}

export function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export function getDefaultLanguage() {
  let lan = getCookie('language');
  if (lan && lan.length > 0) {
    lan = atob(lan);
  } else {
    lan = 'en';
  }
  if (lan != 'zh-TW' && lan != 'en') lan = 'en';

  return lan;
}

export function arrayEquals(array1, array2) {
  if (!array1 || !array2) {
    return false;
  }

  if (array1.length != array2.length) {
    return false;
  }

  const len = array1.length;
  for (let i = 0, l = len; i < l; i++) {
    if (array1[i] instanceof Array && array2[i] instanceof Array) {
      if (!arrayEquals(array1[i], array2[i])) {
        return false;
      }
    } else if (array1[i] != array2[i]) {
      return false;
    }
  }
  return true;
}

export function objectEquals(obj1, obj2) {
  for (var propName in obj1) {
    if (obj1.hasOwnProperty(propName) != obj2.hasOwnProperty(propName)) {
      return false;
    } else if (typeof obj1[propName] !== typeof obj2[propName]) {
      return false;
    }
  }

  for (var propName in obj2) {
    if (obj1.hasOwnProperty(propName) != obj2.hasOwnProperty(propName)) {
      return false;
    } else if (typeof obj1[propName] !== typeof obj2[propName]) {
      return false;
    }

    if (!obj1.hasOwnProperty(propName)) continue;

    if (obj1[propName] instanceof Array && obj2[propName] instanceof Array) {
      if (!arrayEquals(obj1[propName], obj2[propName])) {
        return false;
      }
    } else if (
      obj1[propName] instanceof Object &&
      obj2[propName] instanceof Object
    ) {
      if (!objectEquals(obj1[propName], obj2[propName])) {
        return false;
      }
    } else if (obj1[propName] != obj2[propName]) {
      return false;
    }
  }

  return true;
}

export function arraysObjectEqual(a1, a2) {
  return (
    a1.length === a2.length && a1.every((o, idx) => objectEquals(o, a2[idx]))
  );
}

export function capitalizeEachFirstLetter(string) {
  if (!string || string.length == 0) {
    return '';
  }

  const arr = string.split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const string2 = arr.join(' ');

  return string2;
}

export function capitalizeFirstLetter(string) {
  if (!string || string.length == 0) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isElementInViewport(el) {
  if (!el) return false;
  // Special bonus for those using jQuery
  if (typeof jQuery === 'function' && el instanceof jQuery) {
    el = el[0];
  }
  const rect = el.getBoundingClientRect();

  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function urlQueryString(url) {
  const result = {};
  if (url) {
    const query_string = url.split('?')[1];
    const vars = query_string.split('&');
    for (let i = 0; i < vars.length; i++) {
      if (vars[i]) {
        const pair = vars[i].split('=');
        // If first entry with this name
        if (typeof result[pair[0]] === 'undefined') {
          result[pair[0]] = pair[1];
          // If second entry with this name
        } else if (typeof result[pair[0]] === 'string') {
          const arr = [result[pair[0]], pair[1]];
          result[pair[0]] = arr;
          // If third or later entry with this name
        } else {
          result[pair[0]].push(pair[1]);
        }
      }
    }
  }
  return result;
}

export function toBinary(string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
}

export function fromBinary(binary) {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

function checkHasLDAPAuth() {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: function (response) {
        const { LDAP } = response;
        if (LDAP && LDAP.LdapUrl && LDAP.LdapSearchDN) {
          resolve(response);
        } else {
          reject();
        }
      },
      error: function (jqXHR) {
        reject(jqXHR);
      },
    };
    httpGetLDAPSetting(null, handlers);
  });
}

function checkUserPermissionByName(username, pathname) {
  const handlers = {
    success: function (res) {
      if (res.Active == false) {
        deleteAllCookie();
        createCookie('error_message', 'Your account is not active');
        createCookie('byPass_SSO', true);
        location.replace('/login');
      } else {
        const user_info = {
          id: res._id,
          name: res.Name,
          officename: res.OfficeName,
          email: res.Email.Address,
          domain: res.Domain,
          role: res.Role,
          online: res.Online,
          active: res.Active,
          department: res.Department,
        };
        createCookie('user_info', btoa(toBinary(JSON.stringify(user_info))));
        createCookie(
          'original_user_info',
          btoa(toBinary(JSON.stringify(user_info))),
        );
        location.replace(pathname);
      }
    },
    error: function (jqXHR) {
      console.log(jqXHR.status, jqXHR.statusText, jqXHR.responseJSON.error);
      deleteAllCookie();
      createCookie('error_message', 'User not found');
      createCookie('byPass_SSO', true);
      location.replace('/login');
    },
  };
  httpGetUser(null, username, handlers);
}

function authSSO(ssoUrl, hostname, pathname) {
  const reDir = `https://${hostname}${pathname}`;
  const failTo = `https://${hostname}/login`;
  const sysType = btoa('Cambrain');
  const userKey = btoa('?8rpqmyv385uz5da2m56y5wbp%$vj7#2');
  const url =
    ssoUrl +
    `?ReDirTo=${reDir}&FailTo=${failTo}&sysType=${sysType}&userKey=${userKey}`;
  location.replace(url);
}

export function setAuthCookie(search, pathname) {
  const SSO = window.PORTAL_AUTHSETTING.SSO;
  if (getCookie('AuthData') || !SSO || !SSO.Active) return;
  const hostname = window.location.hostname;
  const { user_name, office_mail } = queryString.parse(search);
  if (user_name && office_mail) {
    fetch(
      `/api/v1/sso?user_name=${user_name}&office_mail=${office_mail}&hostname=${hostname}`,
    )
      .then((response) => response.json())
      .then((data) => {
        const { user } = data;
        checkUserPermissionByName(user, pathname);
        createCookie('user', btoa(user));
      });
  } else {
    authSSO(SSO.SSOUrl, hostname, pathname);
  }
}

export function checkSSOAuthActive() {
  const authSetting = window.PORTAL_AUTHSETTING;
  return authSetting && authSetting.SSO && authSetting.SSO.Active;
}

export function checkLDAPAuthActive() {
  const authSetting = window.PORTAL_AUTHSETTING;
  return authSetting && authSetting.LDAP && authSetting.LDAP.Active;
}

export function humanReadableFileSize(size) {
  if (size === 0) {
    return '0 KB';
  }

  const i = Math.floor(Math.log(size) / Math.log(1000));
  return (
    (size / Math.pow(1000, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  );
}

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        navigate={navigate}
        location={location}
        params={params}
        {...props}
      />
    );
  };
  return Wrapper;
};

export const genRandomString = (length) => {
  const a =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
  const b = [];
  for (let i = 0; i < length; i++) {
    const j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }

  return b.join('');
};

export const getUser = (username) => {
  return new Promise((resolve, reject) => {
    const handlers = {
      success: (response) => {
        resolve(response);
      },
      error: (jqXHR) => {
        reject(jqXHR);
      },
    };
    httpGetUser(null, username, handlers);
  });
};

export const lazyRetry = (componentImport) => {
  return new Promise((resolve, reject) => {
    let refreshTime =
      window.sessionStorage.getItem('retry-lazy-refreshed') || 0;
    componentImport()
      .then((component) => {
        window.sessionStorage.removeItem('retry-lazy-refreshed');
        resolve(component);
      })
      .catch((error) => {
        if (refreshTime < 3) {
          window.sessionStorage.setItem('retry-lazy-refreshed', ++refreshTime);
          return window.location.reload();
        }
        reject(error);
      });
  });
};

export const ENTRYTYPE = {
  SOLUTON: 'solution',
  WORKSPACE: 'workspace',
  MODELBOARD: 'model_board',
  SOLUTONTEMPLATE: 'solution_template',
  WORKSPACETEMPLATE: 'workspace_template',
  EDGE: 'edge',
};

export const PROJECTSTORAGEQUERYTYPE = {
  API: 'api',
  PROMETHEUS: 'prometheus',
};

export function getProjectStorageQueryType() {
  if (window.ENABLE_PROJECT_QUOTA_API) {
    return PROJECTSTORAGEQUERYTYPE.API;
  } else {
    return PROJECTSTORAGEQUERYTYPE.PROMETHEUS;
  }
}

export const SOLUTIONEXPORTSTATUS = {
  FAIL: 'Failed',
  INITIAL: 'Initial',
  SUCCESS: 'Success',
  RUNNING: 'Running',
};

export const SUBSCRIPTION_ENTRY_TYPE = {
  BACKEND: 'backend',
  SETTING: 'setting',
};

export function checkIsMigrating(status) {
  return status === 'Migrating' || status === 'Preparing';
}

export function recordFeatureUsage(info) {
  // record user usages

  const user_info_data = getCookie('user_info');
  let user_info = {};
  if (user_info_data) user_info = JSON.parse(fromBinary(atob(user_info_data)));
  else {
    if (feature.hasOwnProperty('UserName')) {
      user_info.id = '';
      user_info.name = feature.UserName;
      user_info.officename = '';
    }
  }

  const feature = { ...info };
  if (
    feature.hasOwnProperty('ProjectToken') &&
    (feature.ProjectToken == 'undefined' || feature.ProjectToken == '')
  )
    feature.ProjectToken = null;
  if (
    feature.hasOwnProperty('DatasetToken') &&
    (feature.DatasetToken == 'undefined' || feature.DatasetToken == '')
  )
    feature.DatasetToken = null;

  const userusages = {
    UserId: user_info.id,
    UserName: user_info.name,
    OfficeName: user_info.officename,
    Application: feature.Application,
    Category: feature.Category,
    Action: feature.Action,
    ProjectName: feature.ProjectName,
    ProjectToken: feature.ProjectToken,
    DatasetName: feature.DatasetName,
    DatasetToken: feature.DatasetToken,
    Detail: feature.Detail,
    ApplicationName: feature.ApplicationName,
    ApplicationToken: feature.ApplicationToken,
  };
  const handlers = {
    success: function (data) {
      console.log('Add UserUsages Success');
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log(jqXHR, textStatus);
      if (jqXHR.status != 409) {
        console.log(jqXHR.status, jqXHR.statusText);
      }
    },
  };
  httpSetUserUsages(null, userusages, handlers);
}

export function replaceObjectValues(obj, values) {
  const newValues = { ...obj };
  Object.keys(newValues).forEach((item) => {
    if (Object.keys(values).indexOf(item) > -1) {
      newValues[item] = values[item];
    }
  });

  return newValues;
}

export const readResponseTextAsync = async (resp) => {
  const content = await resp.text();
  return content;
};

export const readResponseJSONAsync = async (resp) => {
  const content = await resp.json();
  return content;
};

export const readResponseBlobAsync = async (resp) => {
  const content = await resp.blob();
  return content;
};

export const getCambrianFileServer = (havePrefix = false) => {
  const RES_FILE_SERVER_PORT = getSystemConfig('RES_FILE_SERVER_PORT');

  let serverUrl = `${window.location.origin}`;

  if (RES_FILE_SERVER_PORT) {
    serverUrl = `${window.location.protocol}//${window.location.hostname}:${RES_FILE_SERVER_PORT}`;
  }

  if (!havePrefix) {
    serverUrl += '/file/cambrian';
  }

  return serverUrl;
};
