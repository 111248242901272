exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2MxtkpCKoRJQlHReUOkwwe {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 1rem;\n}\n\n._21_iYt6mRoXe_RYHPnWMLk {\n    min-width: 50%!important;\n    margin-top: 5%;\n    border-radius: 4px;\n    box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.58);\n    font-family: 'Roboto';\n}\n\n._3WndXbM3CApBqiR5aIbmr0{\n    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.29);\n    background-color: #242737;\n}\n\n._1KiZD6rOlIed5aqjL9s3Ce{\n    padding-bottom: 20px;\n    padding-left: 40px;\n    padding-right: 40px;\n    background-color: #cdd2dd;\n}\n\n._1TztBd23PSdRisrkFDf9Ea{\n    padding-bottom: 20px;\n    padding-left: 40px;\n    padding-right: 40px;\n    background-color: #0E243E;\n}\n\n._2NYuaYVvZUo4Y5i3ex4FzP{\n    font-family: Roboto;\n    font-size: 18px;\n    letter-spacing: 0.7px;\n    color: #f4f4f4;\n}\n\n._21_iYt6mRoXe_RYHPnWMLk .TGxHsDBkgWMAMwaAMKqzE{\n    background: none;\n}\n\n._1BnD3Z44voNvxBCW2ieTeT {\n    overflow-x: hidden;\n    word-break: break-word;\n}\n\n.pCHdDFVD9yboIYG_ah7Vk {\n    overflow: hidden;\n}", ""]);

// exports
exports.locals = {
	"modal-footer": "_2MxtkpCKoRJQlHReUOkwwe",
	"modal-wrapper": "_21_iYt6mRoXe_RYHPnWMLk",
	"modal-header": "_3WndXbM3CApBqiR5aIbmr0",
	"modal-body": "_1KiZD6rOlIed5aqjL9s3Ce",
	"modal-body-dark": "_1TztBd23PSdRisrkFDf9Ea",
	"modal-title": "_2NYuaYVvZUo4Y5i3ex4FzP",
	"modal-content": "TGxHsDBkgWMAMwaAMKqzE",
	"modal-body--wrapper": "_1BnD3Z44voNvxBCW2ieTeT",
	"modal-body-new--wrapper": "pCHdDFVD9yboIYG_ah7Vk"
};