import { useState, useEffect, useRef } from 'react';

const OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};

const useIsVisible = () => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef();

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(element);
          }
        });
      }, OPTIONS);
      observer.observe(element);
    }
  }, [elementRef]);

  return [elementRef, isVisible];
};

export default useIsVisible;
