import { ServerAPIPrefix } from '../config/config';
import { apiOptions } from './apiOptions';
import { LicenseErrorCode } from '../Platform/LicenseWarning';
import pureStore from '../store/pureStore';
import { setLicenseInfo } from '../store/licenseSlice';
import { setShowPlatformWarning } from '../store/platformWarningSlice';

const clientLicenseApiPrefix = `${ServerAPIPrefix}cambrianServiceProxy/`;
export const urlGetLicenseStatus = `${clientLicenseApiPrefix}license/get_license_status`;

export const httpCheckClientLicense = async () => {
  const thisOptions = apiOptions();
  const response = await fetch(urlGetLicenseStatus, { ...thisOptions });
  let resultData = {};
  let err = '';

  try {
    resultData = await response.json();
    if (!response.ok) {
      err = resultData;
    } else {
      return JSON.stringify(resultData);
    }
  } catch (e) {
    err = response.statusText
      ? response.status + ' ' + response.statusText
      : response.status;
  }

  if (err) {
    throw new Error(JSON.stringify({ status: response.status, error: err }));
  }
};

export const promiseCheckClientLicense = async () => {
  httpCheckClientLicense()
    .then((data) => {
      // success

      const result = JSON.parse(data);

      const licenseInformation = {
        licenseType: 'Basic',
        licenseExpire: '',
        isLicenseValid: true,
        errorCode: '',
      };
      if (result.expires_at) {
        licenseInformation.licenseExpire = result.expires_at;
      }

      pureStore.dispatch(setLicenseInfo(licenseInformation));
      pureStore.dispatch(setShowPlatformWarning(false));
    })
    .catch((error) => {
      console.log(error);
      const err = JSON.parse(error.message);

      const licenseInformation = {
        licenseType: 'Basic',
        licenseExpire: '',
        isLicenseValid: false,
        errorCode: '500',
      };

      if (err?.error?.resp_code) {
        if (
          Object.keys(LicenseErrorCode).indexOf(
            err.error.resp_code.toString(),
          ) > -1
        ) {
          licenseInformation.errorCode = err.error.resp_code.toString();
        } else {
          licenseInformation.errorCode = '500';
        }
      } else {
        licenseInformation.errorCode = '500';
      }

      if (err?.error?.expires_at) {
        licenseInformation.licenseExpire = err.error.expires_at;
      }

      pureStore.dispatch(setLicenseInfo(licenseInformation));
      pureStore.dispatch(setShowPlatformWarning(true));
    });
};
