import React, { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getCookie, createCookie, fromBinary } from '../common';
import i18n from 'i18next';

import * as api from '../user_utils';
import { checkIsFunctionEnabled } from '../utils/common_utils';

const language_list = {
    'en': "English",
    'zh-TW': "繁體中文"
}

const CustomToggle = forwardRef((props, ref) => {

    const handleClick = (e) => {
        e.preventDefault();
        props.onClick(e);
    }

    return (
        <button
            ref={ref}
            className="btn btn-md"
            onClick={handleClick}
        >
            {props.children}
        </button>
    );
});

class ConfigLanguage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: language
        };
        this.getLanguage = this.getLanguage.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
    }
    componentDidMount() {
        this.getLanguage();
    }

    getLanguage() {
        if(!checkIsFunctionEnabled("ENABLE_SWITCH_LANGUAGE")){
            lan = "en";
            i18n.changeLanguage(lan);
            window.language = lan;
            this.setState({
                language: lan
            });
            return
        }

        var lan = getCookie('language');
        if (lan && lan.length > 0) {
            lan = atob(lan);
            i18n.changeLanguage(lan);
            window.language = lan;
            this.setState({
                language: lan
            });
        } else {
            let username = atob(getCookie("user"));
            let handlers = {
                success: function (data) {
                    console.debug("getLanguage: " + data);
                    let lang = "";
                    if (data && data.length > 0) {
                        lang = data;
                    } else {
                        lang = window.language;
                    }
                    i18n.changeLanguage(lang);
                    window.language = lang;
                    createCookie("language", btoa(lang));
                    this.setState({
                        language: lang
                    });
                },
                error: function(jqXHR, textStatus, errorThrown){
                    console.log(jqXHR, textStatus);
                    i18n.changeLanguage(window.language);
                    createCookie("language", btoa(window.language));
                    this.setState({
                        language: window.language
                    });
                }
            };
            api.httpGetUserLanguage(this, username, handlers);
        }
    }

    setLanguage(value) {
        createCookie("language", btoa(value));
        i18n.changeLanguage(value);
        window.language = value;
        this.setState({
            language: value,
        });

        let user_info = JSON.parse(fromBinary(atob(getCookie("user_info"))));
        var user = {
            Name: user_info.name,
            Language: value
        };

        let handlers = {
            success: function (data) {
                console.debug("set user language");
            },
            error: function(jqXHR, textStatus, errorThrown){
                console.log(jqXHR, textStatus);
            },
            statusCode: {
                409: function() {
                    console.log("[409]cannot updated");
                },
            }
        };
        api.httpUpdateUser(this, user, handlers);
    }

    render() {
        return (
            <Dropdown
                className="NavbarDpBtn"
                onSelect={(e)=>this.setLanguage(e)}
            >
                <Dropdown.Toggle as={CustomToggle}>
                    <FontAwesomeIcon icon={solid('globe')} style={{fontSize:"16px", color:'white'}}/>
                </Dropdown.Toggle>

                <Dropdown.Menu >
                    {
                        Object.keys(language_list).map((key, idx) =>
                            <Dropdown.Item className="NavbarDpBtn-link" eventKey={key} key={idx}>{language_list[key]}</Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export {
    ConfigLanguage
};
