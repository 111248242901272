export const checkIsFunctionEnabled = (funcName) => {
  // this is to check if function is enabled or disabled by environment.

  let FUNCTION_ENABLED;
  if (window.SYSTEM_CONFIG.FUNCTION_ENABLED) {
    FUNCTION_ENABLED = window.SYSTEM_CONFIG.FUNCTION_ENABLED;
  }

  if (!FUNCTION_ENABLED || Object.keys(FUNCTION_ENABLED) == 0) {
    return false;
  }

  if (!FUNCTION_ENABLED[funcName]) {
    return false;
  }

  return true;
};

export const getSystemConfig = (key) => {
  if (!window.SYSTEM_CONFIG) {
    return undefined;
  }

  return window.SYSTEM_CONFIG[key];
};

export const getIplasConfig = (key) => {
  if (!window.IPLAS_CONFIG) {
    return undefined;
  }

  return window.IPLAS_CONFIG[key];
};

export const getCheckedItemFromTable = (objectSelectedItems, arrItems, key) => {
  const allSelectedItems = Object.keys(objectSelectedItems).filter((item) => {
    return objectSelectedItems[item] == true;
  });

  const arrSelectedItems = arrItems.filter((item) => {
    return allSelectedItems.indexOf(item[key]) > -1;
  });

  return arrSelectedItems;
};
