import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { http_get_password_forget_trigger, http_post_password_forget_apply } from "../api/auth";


export const Forget_Password = (props) => {
    const { t } = useTranslation('translation');
    const { on_back_login } = props;

    const [email, set_email] = useState('');
    const [message, set_message] = useState('Please submit email to get CAPTCHA');
    const [captcha, set_captcha] = useState('');
    const [password_1, set_password_1] = useState('');
    const [password_2, set_password_2] = useState('');

    const [expired_time, set_expired_time] = useState(0);

    const [is_loading, set_is_loading] = useState(false);

    const timer_ref = useRef(null);
    const count_ref = useRef(0);

    useEffect(() => {

        return () => clearInterval(timer_ref.current);
    }, []);

    const forget_password_trigger = () => {
        if (email.length == 0 || !email.includes('@') || !email.includes('.')) {
            set_message('Please fill email (ex: a@b.c)');

            return;
        }

        set_is_loading(true);
        http_get_password_forget_trigger(email)
            .then((response) => {
                count_ref.current = response.expired_time;
                set_expired_time(count_ref.current);

                timer_ref.current = setInterval(() => {
                    count_ref.current = count_ref.current - 1;
                    set_expired_time(count_ref.current);

                    if (count_ref.current <= 0) {
                        count_ref.current = 0;
                        set_expired_time(count_ref.current);
                        clearInterval(timer_ref.current);
                        set_message('Please submit email to get CAPTCHA');
                    }
                }, 1000);

                set_message('Please fill CAPTCHA (from email) and new passwords');
            })

            .catch(reason => {
                let error_message = reason.message;
                if (error_message.includes('Get User Information Failed'))
                    error_message = 'Email not found';
                else if (error_message.includes('not support Forget Password in login_type ='))
                    error_message = 'The user is not a standard account by this email'
                else if (error_message.includes('i/o timeout'))
                    error_message = 'Network timeout';

                set_message(error_message);
                console.error(reason);
            })

            .finally(() => {
                set_is_loading(false);
            });
    };

    const forget_password_apply = () => {
        if (password_1 != password_2) {
            set_message('New passwords do not match');

            return;
        }
        if (password_1.length < 5) {
            set_message('New password must be >= 5 chars');

            return;
        }

        set_is_loading(true);
        http_post_password_forget_apply(email, captcha, password_1)
            .then((response) => {
                toastr.success(`Success`, '', {timeOut: 1000})

                set_expired_time(0);
                on_back_login();
            })

            .catch(reason => {
                let error_message = reason.message;
                if (error_message.includes('Update user password operation Fail'))
                    error_message = 'CAPTCHA is incorrect';

                set_message(error_message);
                console.error(reason);
            })

            .finally(() => {
                set_is_loading(false);
            });
    };

    return (<>
        <div style={{ maxWidth: '450px' }}>
            <div className="login--gold--text" style={{ marginTop: "20px", textShadow: '1px 1px 2px black' }}>
                {message}
            </div>
            {!expired_time &&
            <>
                <div style={{ display: 'flex' }}>
                    <label className="form-control input--black_opacity login__user--input"
                           style={{
                               width: '210px', color: 'white', fontSize: '16px',
                               textShadow: '1px 1px 2px black',
                               backgroundColor: 'rgb(10, 10, 10, 0)'
                           }}
                    >
                        Email:
                    </label>
                    <input type="text" autoFocus
                           className="form-control input--black_opacity login__user--input"
                           style={{ backgroundColor: 'rgb(10, 10, 10, 0.9)' }}
                           value={email}
                           onChange={(event) => {
                               set_email(event.target.value.toLowerCase());
                           }}
                           onKeyDown={(event) => {
                               if (event.key === 'Enter') {
                                   forget_password_trigger();
                               }
                           }}
                           disabled={expired_time}
                    />
                </div>
            </>
            }

            {!!expired_time &&
            <>
                <div style={{ display: 'flex' }}>
                    <label className="form-control input--black_opacity login__user--input"
                           style={{
                               width: '210px', color: 'white', fontSize: '16px',
                               textShadow: '1px 1px 2px black',
                               backgroundColor: 'rgb(10, 10, 10, 0)'
                           }}
                    >
                        CAPTCHA:
                    </label>
                    <input type="text"
                           className="form-control input--black_opacity login__user--input"
                           value={captcha}
                           onChange={(event) => {
                               set_captcha(event.target.value);
                           }}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <label className="form-control input--black_opacity login__user--input"
                           style={{
                               width: '210px', color: 'white', fontSize: '16px',
                               textShadow: '1px 1px 2px black',
                               backgroundColor: 'rgb(10, 10, 10, 0)'
                           }}
                    >
                        New Password:
                    </label>
                    <input type="password"
                           className="form-control input--black_opacity login__user--input"
                           value={password_1}
                           onChange={(event) => {
                               set_password_1(event.target.value);
                           }}
                           onKeyDown={(event) => {
                               if (event.key === 'Enter') {
                                   forget_password_apply();
                               }
                           }}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <label className="form-control input--black_opacity login__user--input"
                           style={{
                               width: '210px', color: 'white', fontSize: '16px',
                               backgroundColor: 'rgb(10, 10, 10, 0)'
                           }}
                    >
                        New Password:
                    </label>
                    <input type="password"
                           className="form-control input--black_opacity login__user--input"
                           value={password_2}
                           onChange={(event) => {
                               set_password_2(event.target.value);
                           }}
                           onKeyDown={(event) => {
                               if (event.key === 'Enter') {
                                   forget_password_apply();
                               }
                           }}
                    />
                </div>
            </>
            }

            <a className="btn btn-md btn--login feature-usage" style={{ float: "right", width: '150px', marginTop: "13px" }}
               data-feature={'{"Application":"Cambrian", "UserName":"' + email + '", "Category":"Login", "Action":"Forget Password"}'}
               onClick={() => {
                   if (!expired_time)
                       forget_password_trigger();
                   else
                       forget_password_apply();
               }}
            >
                <span>
                    {is_loading &&
                        <FontAwesomeIcon icon={solid('spinner')} spin />
                    }
                    &nbsp;SUBMIT&nbsp;
                </span>
                <sub>{expired_time && !is_loading ? `in ${expired_time} sec` : ''}</sub>
            </a>
            <a className="btn btn-md btn--login feature-usage" style={{ marginTop: "13px", float: "left" }}
               onClick={() => {
                   on_back_login();
               }}
            >
                Back to Login
            </a>
        </div>
    </>);
}
