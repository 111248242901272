import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  recording: false,
  isPlaying: false,
  checkAudio: null,
  responseAudio: null,
  isPlayingResponse: false,
};

const slice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setRecording: (state, action) => {
      state.recording = action.payload;
    },
    toggleIsPlaying: (state, action) => {
      state.isPlaying = !state.isPlaying;
    },
    setCheckAudio: (state, action) => {
      state.checkAudio = action.payload;
    },
    setResponseAudio: (state, action) => {
      state.responseAudio = action.payload;
    },
    setIsPlayingResponse: (state, action) => {
      state.isPlayingResponse = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
  },
});

export const {
  setRecording,
  toggleIsPlaying,
  setCheckAudio,
  setResponseAudio,
  setIsPlayingResponse,
  setIsPlaying,
} = slice.actions;

export default slice.reducer;
