import React, { useEffect, useState } from 'react'
import PromotionalVideosCard from "./PromotionalVideosCard"
import classes from "./PromotionalVideos.module.css"
import { httpGetPromotionalVideosInfo } from '../api/files'
import useHttp from '../CustomHook/use-http'

import Loading from '../UI/Loading'

const PromotionalVideos = () => {
    const [listItem, setListItem] = useState([])
    const [isLoading, setIsLoading] = useState([])

    const disableRightClick = (event) => {
        event.preventDefault()
    }

    const {
        sendRequest,
        status,
        data: resultData,
        error,
    } = useHttp(httpGetPromotionalVideosInfo);

    useEffect(() => {
        setIsLoading(false)
        if (status == 'success') {
            if(resultData) {
                if(resultData['videos']){
                    setListItem(resultData['videos'])
                }
            }
        }

    }, [status, resultData])

    useEffect(() => {
        setIsLoading(true)
        sendRequest()

        document.body.addEventListener('contextmenu', disableRightClick)

        return(
            document.removeEventListener('contextmenu', disableRightClick)
        )

    }, [])

    if(isLoading){
        return(
            <Loading />
        )
    }

    if(!isLoading){
        return(
            <div className={`width80 col-centered white-panel-style ${classes['application--wrapper']}`}>
                {listItem.length == 0 &&
                    <div style={{color: "white", textAlign: "center"}}>
                        <h3>No videos</h3>
                    </div>
                }
                {listItem.length > 0 &&
                    <PromotionalVideosCard
                        list = {listItem}
                    />
                }
            </div>
        )
    }
}

export default PromotionalVideos