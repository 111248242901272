import * as Config from './config/config';
import { b64toBlob } from './ModelTrainBoard/common';

function httpGetSolutionModels(context, token, version, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}solutions/${token}/models?_version=${version}`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetModelsUsage(context, data, type, projectQueryType, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/all/usage?_type=${type}&_projectQueryType=${projectQueryType}`,
        type: 'POST',
        //dataType: 'json',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetModel(context, token, version, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}`;
    if (version)
        url += `?_version=${version}`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetEdgeData(context, token, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}solutions/${token}/edgedata`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpTriggerTask(context, owner, token, version, submodel, from, data, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks?_version=${version}&_submodel=${submodel}&_owner=${owner}&_from=${from}`,
        type: 'PUT',
        //dataType: 'json',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTasks(context, token, version, submodel, noChild, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/tasks?_submodel=${submodel}&_nochild=${noChild}`;
    if (version)
        url += `&_version=${version}`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTasksByStatus(context, token, version, submodel, status, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/tasks_by_status?_submodel=${submodel}&_status=${status}`;
    if (version)
        url += `&_version=${version}`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTasksWithStatus(context, token, version, submodel, noChild, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/tasks_sync_status?_submodel=${submodel}&_nochild=${noChild}`;
    if (version)
        url += `&_version=${version}`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTasksWithStatusByIds(context, token, submodel, data, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/tasks_sync_status?_submodel=${submodel}`;
    var settings = {
        url: url,
        type: 'POST',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTasksByIds(context, token, submodel, data, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/tasks_by_ids?_submodel=${submodel}`;
    var settings = {
        url: url,
        type: 'POST',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTask(context, token, taskid, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpCancelTask(context, token, submodel, taskid, username, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/cancel?_submodel=${submodel}&_user=${username}`,
        type: 'DELETE',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpEarlyStopTask(context, token, submodel, taskid, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/earlystop?_submodel=${submodel}`,
        type: 'POST',
        contentType: 'application/json',
        context: context,
        processData: false,
        //data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpReleaseTask(context, token, taskid, weight, deploy, data, entry, username, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/builds?_weight=${weight}&_user=${username}&_entry=${entry}`
    if (deploy)
        url += `&_deploy=${deploy}`;
    var settings = {
        url: url,
        type: 'POST',
        //dataType: 'json',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpDeleteTask(context, token, taskid, data, username, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}?_user=${username}`,
        type: 'DELETE',
        //dataType: 'json',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpDeleteVersionTasks(context, token, version, data, username, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks?_version=${version}&_user=${username}`,
        type: 'DELETE',
        //dataType: 'json',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpResumeTask(context, token, taskid, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}pipelineProxy/experiment/${taskid}/resume`,
        type: 'POST',
        contentType: 'application/json',
        context: context,
        processData: false
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpChangeWeightStatus(context, data, handlers) {
    const {modelName, token, experimentId, status, weight} = data
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/qualityStatus?_experimentId=${experimentId}&_weight=${weight}&_status=${status}&_modelName=${modelName}`,
        type: 'POST',
        contentType: 'application/json',
        context: context,
        processData: false
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetClassNum(context, token, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/classnum`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTaskEvalResult(context, token, submodel, taskid, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}pipelineProxy/modelboard/${taskid}?token=${token}&name=${submodel}`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTaskReleaseBuilds(context, token, taskid, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/builds`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetReleaseBuilds(context, token, version, submodel, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/builds?_submodel=${submodel}`;
    if (version)
        url = `&_version=${version}`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetReleaseBuild(context, token, buildId, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/builds/${buildId}`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetReleaseBuildsWithStatus(context, token, version, submodel, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/builds_sync_status?_submodel=${submodel}`;
    if (version)
        url += `&_version=${version}`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetReleaseBuildsWithStatusByIds(context, token, submodel, data, handlers) {
    let url = `${Config.ServerAPIPrefix}models/${token}/builds_sync_status?_submodel=${submodel}`;
    var settings = {
        url: url,
        type: 'POST',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTaskArtifact(context, token, taskid, type, handlers) {

        var settings = {
        url: `${Config.ServerAPIPrefix}pipelineProxy/experiment/${taskid}/artifact/${type}?token=${token}`,
        type: 'GET',
        xhrFields: { responseType: 'blob' },
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetReleaseArtifact(context, token, taskid, buildid, type, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}pipelineProxy/release/releaseLog/${buildid}?token=${token}`,
        type: 'GET',
        xhrFields: { responseType: 'blob' },
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpSyncProjectTrainTasks(context, token, handlers){
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks`,
        type: 'POST',
        //dataType: 'json',
        // contentType: 'application/json',
        context: context,
        // processData: false,
        // data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetSubmodels(context, token, submodel, version, handlers) {
    let url = `${Config.ServerAPIPrefix}pipelineProxy/model/getModelList?token=${token}`;
    if (submodel) {
        url += `&model_name=${submodel}`;
        if (version)
            url += `&version=${version}`;
    }
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTaskMetric(context, token, submodel, taskid, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}pipelineProxy/modelboard/${taskid}/detailed_metric?token=${token}`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTaskFATable(context, token, submodel, taskid, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}pipelineProxy/modelboard/${taskid}/detailed_table?token=${token}`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetCvatFAPreviewURL(context, token, taskid, data, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/fa_to_cvat`,
        type: 'POST',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}
function httpDeleteCvatFAData(context, token, taskid, labelId, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/fa_to_cvat?_id=${labelId}`,
        type: 'DELETE',
        context: context,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpCompressWeight(context, token, taskid, weight, username, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/transform?_weight=${weight}&_user=${username}`,
        type: 'POST',
        contentType: 'application/json',
        context: context,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpModelWebCamPredict(context, token, data, handlers) {
    let headers = {     "Service-Token": token,
                        "Service-Name": data.Submodel,
                        "Service-Version": data.Version,
                        "Model-Quality": "test",
                        "Return-Curl-Cmd": true
                  }

    if(data.WeightVersion){
        headers["Service-Weight"] = data.WeightVersion
    }

    let formData = new FormData();
    let formFile = new FormData();
    let combinedFormData = new FormData();

    let blob = data.Image

    try{
        //from base64 string
        blob = b64toBlob(data.Image);
        formFile.append(
            "dummyFileName",
            blob
        )
    }catch(e){
        formFile.append(
            "dummyFileName",
            blob,
            "dummyFileName"
        );

    }

    let input_json = {
                        inputs: [{
                            part_id: data.part_id ? data.part_id : "1",
                            input_id: data.input_id ? data.input_id : 1,
                            product_name: data.product_name ? data.product_name : "dummy",
                            height: data.Height,
                            width: data.Width,
                            file_name: "dummyFileName",
                            data_type: "image"
                    }]
    }

    const json = new Blob([JSON.stringify(input_json)], { type: "application/json" });

    formData.append(
        "input_json",
        json,
        "input_json"
    );

    formData.forEach(function(value, key) {
        combinedFormData.append(key, value);
    });

    formFile.forEach(function(value, key) {
        combinedFormData.append(key, value);
    });

    let settings = {
        url: `${Config.ServerAPIPrefix}p/model_mgr/serving/predict`,
        type: 'POST',
        headers: headers,
        enctype: 'multipart/form-data',
        context: context,
        contentType: false,
        processData: false,
        data: combinedFormData,
    }

    Object.assign(settings, handlers);
    $.ajax(settings);
}

function http_predict_by_file_list(context, model_info, file_list, handler_dict) {
    let header_dict = {
        "Service-Token": model_info.model_token,
        "Service-Name": model_info.model_name,
        "Service-Version": model_info.model_version,
        "Model-Quality": "test",
        "Return-Curl-Cmd": true,
    }

    if(model_info.model_weight){
        header_dict["Service-Weight"] = model_info.model_weight
    }

    let form_data = new FormData();
    let form_file = new FormData();
    let input_json = {inputs: []};

    file_list.forEach((file, index) => {
        input_json.inputs.push({
            part_id: model_info.part_id || "1",
            input_id: model_info.input_id || 1,
            product_name: model_info?.product_name || "dummy",
            height: file.height,
            width: file.width,
            file_name: file.name,
            data_type: "image",
        });

        form_file.append(
            file.name,
            file,
        );
    });

    form_data.append(
        "input_json",
        new Blob([JSON.stringify(input_json)], { type: "application/json" }),
    );

    form_file.forEach(function(value, key) {
        form_data.append(key, value);
    });

    let setting_dict = {
        url: `${Config.ServerAPIPrefix}p/model_mgr/serving/predict`,
        type: 'POST',
        headers: header_dict,
        enctype: 'multipart/form-data',
        context: context,
        contentType: false,
        processData: false,
        data: form_data,
    };

    Object.assign(setting_dict, handler_dict);
    $.ajax(setting_dict);
}

function httpCreatePredictTask(context, token, taskid, data, username, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/create_predict_task?_user=${username}`,
        type: 'POST',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpCheckPredictImage(context, token, taskid, predictId, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/check_predict_image?_predictId=${predictId}`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpUpdateGPUNum(context, token, taskid, gpuNum, handlers) {

    var settings = {
        url: `${Config.ServerAPIPrefix}pipelineProxy/pipelineQueue/${token}?experiment_id=${taskid}&gpuNum=${gpuNum}`,
        type: 'PUT',
        contentType: 'application/json',
        context: context,
        processData: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetGPUUSageByToken(context, token, username, handlers) {

    var settings = {
        url: `${Config.ServerAPIPrefix}pipelineProxy/model/group_gpu_usage_by_token?token=${token}&userName=${username}`,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpSetTaskTags(context, token, taskid, data, upsert, handlers) {
    let _upsert = upsert? "true":"false";
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasks/${taskid}/tags?_upsert=${_upsert}`,
        type: 'POST',
        contentType: 'application/json',
        context: context,
        processData: false,
        data: JSON.stringify(data),
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTaskUsage(context, token, submodel, handlers) {
    let url = `${Config.ServerAPIPrefix}pipelineProxy/project/${token}/status_statistic`;
    if (submodel)
        url += `?model_name=${submodel}`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetModelDetail(context, token, submodel, version, handlers) {
    const url = `${Config.ServerAPIPrefix}pipelineProxy/model/detail?token=${token}&modelName=${submodel}&version=${version}`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTaskConfig(context, token, taskid, handlers) {
    let url = `${Config.ServerAPIPrefix}pipelineProxy/experiment/${taskid}/config`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetWeightInfo(context, taskId, handlers) {
    const url = `${Config.ServerAPIPrefix}pipelineProxy/experiment/${taskId}/weights`;
    var settings = {
        url: url,
        type: 'GET',
        context: context,
        cache: false,
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetDefaulWeightListByModel(token, modelName, modelVersion, handlers) {

    const settings = {
        url: `${Config.ServerAPIPrefix}pipelineProxy/model/weights?token=${token}&model_name=${modelName}&model_version=${modelVersion}`,
        type: 'GET',
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetModelOnlineDeploymentCount(context, token, modelName, handlers) {
    const settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/online_deployment?modelname=${modelName}`,
        type: 'GET',
        context
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetTaskAndWeights(token, modelname, sync, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/tasksandweight?modelname=${modelname}&sync=${sync}`,
        type: 'GET',
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

function httpGetReleaseBuildsNew(token, modelname, handlers) {
    var settings = {
        url: `${Config.ServerAPIPrefix}models/${token}/buildsnew?modelname=${modelname}`,
        type: 'GET',
    }
    Object.assign(settings, handlers);
    $.ajax(settings);
}

export {
    httpGetSolutionModels,
    httpGetModel,
    httpGetEdgeData,
    httpGetModelsUsage,
    httpTriggerTask,
    httpGetTasks,
    httpGetTasksWithStatus,
    httpGetTasksWithStatusByIds,
    httpGetTasksByStatus,
    httpGetTask,
    httpCancelTask,
    httpEarlyStopTask,
    httpReleaseTask,
    httpGetClassNum,
    httpGetTaskEvalResult,
    httpGetTaskReleaseBuilds,
    httpGetReleaseBuilds,
    httpGetReleaseBuild,
    httpGetReleaseBuildsWithStatus,
    httpGetReleaseBuildsWithStatusByIds,
    httpGetTaskArtifact,
    httpGetReleaseArtifact,
    httpSyncProjectTrainTasks,
    httpGetSubmodels,
    httpGetTaskMetric,
    httpGetTaskFATable,
    httpGetCvatFAPreviewURL,
    httpDeleteCvatFAData,
    httpDeleteTask,
    httpDeleteVersionTasks,
    httpCompressWeight,
    httpModelWebCamPredict,
    http_predict_by_file_list,
    httpCreatePredictTask,
    httpCheckPredictImage,
    httpUpdateGPUNum,
    httpGetGPUUSageByToken,
    httpSetTaskTags,
    httpGetTaskUsage,
    httpGetModelDetail,
    httpGetTaskConfig,
    httpResumeTask,
    httpChangeWeightStatus,
    httpGetTasksByIds,
    httpGetWeightInfo,
    httpGetDefaulWeightListByModel,
    httpGetModelOnlineDeploymentCount,
    httpGetTaskAndWeights,
    httpGetReleaseBuildsNew
}
