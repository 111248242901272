import { createSlice } from '@reduxjs/toolkit';
import { clearStorageItems } from '../Solution_v2/constant';

const initialState = {
  recordUserFeatureEntries: {},
  memberPermissions: [],
  solutionModels: [],
  cvatTaskId: null,
  cvatUrl: null,
  cvatInsertId: null,
  model: null,
  jobId: null,
  solution: null,
  subModel: [],
  permission: 'none',
  groupResourceInfo: null,
  subscriptionInfo: null,
  photoList: [], // for now this is not used. but keep it here for later implementation. previous code also not removed but it's better to save this onto sessionStorage only
  activePrompt: true,
  isPromptUnblock: false,
  projectTrainConfig: {
    selectedModel: '',
    ver: '',
    userModelName: '',
    _from: '',
  },
};

const slice = createSlice({
  name: 'solution',
  initialState,
  reducers: {
    setRecordUserFeatureEntries: (state, action) => {
      state.recordUserFeatureEntries = {
        ...state.recoredUserFeatureEntries,
        ...action.payload,
      };
    },
    setMemberPermissions: (state, action) => {
      state.memberPermissions = action.payload;
    },
    setSolutionModels: (state, action) => {
      state.solutionModels = action.payload;
    },
    initNewTraningState: (state, action) => {
      // true or default will init all state
      if (action.payload !== false) {
        state.jobId = initialState.jobId;
        state.cvatInsertId = initialState.cvatInsertId;
      }

      if (action.payload === 'all') {
        state.projectTrainConfig = initialState.projectTrainConfig;
      }

      state.cvatUrl = initialState.cvatUrl;
      state.photoList = initialState.photoList;
      state.cvatTaskId = initialState.cvatTaskId;

      clearStorageItems(action.payload);
    },
    setCvatTaskId: (state, action) => {
      state.cvatTaskId = action.payload;
    },
    setCvatUrl: (state, action) => {
      state.cvatUrl = action.payload;
    },
    setCvatInsertId: (state, action) => {
      state.cvatInsertId = action.payload;
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
    setSolution: (state, action) => {
      state.solution = action.payload;
    },
    setSubModel: (state, action) => {
      state.subModel = action.payload;
    },
    setPhotoList: (state, action) => {
      state.photoList.push(action.payload);
    },
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
    setGroupResourceInfo: (state, action) => {
      state.groupResourceInfo = action.payload;
    },
    setSubscriptionInfo: (state, action) => {
      state.subscriptionInfo = action.payload;
    },
    setActivePrompt: (state, action) => {
      state.activePrompt = action.payload;
    },
    setIsPromptUnblock: (state, action) => {
      state.isPromptUnblock = action.payload;
    },
    setProjectTrainConfig: (state, action) => {
      state.projectTrainConfig = action.payload;
    },
  },
});

export const {
  setRecordUserFeatureEntries,
  setMemberPermissions,
  setSolutionModels,
  setCvatTaskId,
  setCvatUrl,
  setCvatInsertId,
  setModel,
  setJobId,
  setSolution,
  setPhotoList,
  setPermission,
  setActivePrompt,
  setIsPromptUnblock,
  setSubscriptionInfo,
  setGroupResourceInfo,
  setSubModel,
  initNewTraningState,
  setProjectTrainConfig,
} = slice.actions;

export default slice.reducer;
