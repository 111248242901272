import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sortInfo: {
    columnName: '',
    order: -1,
  },
  page: 1,
};

const tableSortSlice = createSlice({
  name: 'tableSort',
  initialState,
  reducers: {
    setSortInfo: (state, action) => {
      state.sortInfo = action.payload;
    },
    increasePage: (state, action) => {
      state.page += 1;
    },
    decreasePage: (state, action) => {
      state.page -= 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    initSortInfoState: (state, action) => {
      return initialState;
    },
  },
});

export const {
  setSortInfo,
  increasePage,
  decreasePage,
  setPage,
  initSortInfoState,
} = tableSortSlice.actions;
export default tableSortSlice.reducer;
