export const b64ToFile = (base64Strings, fileName, mimeType) => {
  // base64ToFile
  // Combine base64 strings into a single binary string
  const binaryString = base64Strings.join('');

  // Decode the binary string to an array buffer
  const arrayBuffer = new Uint8Array(
    [...atob(binaryString)].map((char) => char.charCodeAt(0)),
  );

  // Create a Blob from the array buffer
  const blob = new Blob([arrayBuffer], { type: mimeType });

  // Create a File object from the Blob
  return new File([blob], fileName, { type: mimeType });
};

export const b64toBlob = (b64Data) => {
  // b64toBlob

  const contentType = b64Data.split(',')[0].split(':')[1].split(';')[0];
  const b64String = b64Data.split(';base64,')[1];

  const byteCharacters = atob(b64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: contentType });
  return blob;
};

export const MODAL_MODE = {
  MODEL_INFO: 'modelInformation',
  MODEL_DATASETS: 'modelDatasets',
  DELETE_TASK: 'deleteTask',
  CANCEL_TASK: 'cancelTask',
  RESUME_TASK: 'resumeTask',
  EARLY_STOP: 'earlyStop',
};

const STATUS_STYLE = {
  DEFAULT_STYLE: { color: '#6e6e6e' },
  SUCCEEDED_STYLE: { color: '#12892a' },
  FAILED_STYLE: { color: '#972121' },
  RUNNING_STYLE: { color: '#0075ff' },
  CANCELED_STYLE: { color: '#6e6e6e' },
};

export const QUALITY = {
  RELEASED: 'released',
  DEFAULT: 'default',
  TEST: 'test',
};

export const DEPLY_WEIGHT_MAP = {
  orininal: 'weights',
  onnx: 'weights_onnx',
  openvino: 'weights_openvino',
};

const train_performance_sample_count = 1000;
const EXPERIMENT_SUCCEED_STATUS = ['Succeeded'];
const EXPERIMENT_FAILED_STATUS = ['Error', 'Failed'];
const EXPERIMENT_CANCELED_STATUS = ['Canceled'];
const EXPERIMENT_STOP_STATUS = ['Succeeded', 'Error', 'Failed', 'Canceled'];
const EXPERIMENT_RUN_STATUS = ['Initial', 'Queue', 'Started', 'Running'];
const EXPERIMENT_PIPELINE_RUN_STATUS = ['Queue', 'Started', 'Running'];
const EXPERIMENT_QUEUE_STATUS = ['Queue'];
export const RELEASE_COMPRESS_FORMAT = ['fp32', 'fp16'];

export const QualityList = [
  {
    name: 'Online',
    value: QUALITY.RELEASED,
    description: 'Platform can use this model to do Predict.',
  },
  {
    name: 'Online Default',
    value: QUALITY.DEFAULT,
    description: 'Default use this model if version not be specified.',
  },
  {
    name: 'Offline',
    value: QUALITY.TEST,
    description: 'Not Ready for online.',
  },
];

export function isExperimentSucceeded(status) {
  return EXPERIMENT_SUCCEED_STATUS.indexOf(status) != -1;
}
export function isExperimentFailed(status) {
  return EXPERIMENT_FAILED_STATUS.indexOf(status) != -1;
}
export function isExperimentCanceled(status) {
  return EXPERIMENT_CANCELED_STATUS.indexOf(status) != -1;
}
export function isExperimentStopped(status) {
  return EXPERIMENT_STOP_STATUS.indexOf(status) != -1;
}
export function isExperimentRunning(status) {
  return EXPERIMENT_RUN_STATUS.indexOf(status) != -1;
}
export function isPipelineExperimentRunning(status) {
  return EXPERIMENT_PIPELINE_RUN_STATUS.indexOf(status) != -1;
}
export function isExperimentQueue(status) {
  return EXPERIMENT_QUEUE_STATUS.indexOf(status) != -1;
}

export function isQualityOffline(quality) {
  return QualityList.find((q) => q.name === quality).value === QUALITY.TEST;
}

export function getStatusStyle(status) {
  let result = STATUS_STYLE.DEFAULT_STYLE;
  if (isExperimentRunning(status)) {
    result = STATUS_STYLE.RUNNING_STYLE;
  } else if (isExperimentSucceeded(status)) {
    result = STATUS_STYLE.SUCCEEDED_STYLE;
  } else if (isExperimentCanceled(status)) {
    result = STATUS_STYLE.CANCELED_STYLE;
  } else if (isExperimentFailed(status)) {
    result = STATUS_STYLE.FAILED_STYLE;
  }
  return result;
}

function parseEvalDataToChart(chartKPI, evalData) {
  return chartKPI.reduce((prev, current) => {
    if (!evalData[current]) return prev;
    prev.push({
      x: evalData.epoch,
      y: evalData[current],
      name: current,
      type: 'scatter',
      mode: 'lines+markers',
      hoverlabel: { bgcolor: '#222' },
      hovertemplate: '<b>%{y}</b><extra></extra>',
    });
    return prev;
  }, []);
}

export function SetProgressEle(task, evalResult) {
  let progress = 0;
  const epochMax = evalResult.max_epoch;
  const trainResult = evalResult.train_result;
  if (isExperimentSucceeded(task.Status)) {
    progress = 100;
  } else if (evalResult && !evalResult.errorMsg && epochMax && trainResult) {
    let epoch_finished = 0;
    if (trainResult?.epoch.length > 0) {
      epoch_finished = trainResult.epoch[trainResult.epoch.length - 1] || 0;
    }
    progress = Math.floor((epoch_finished / epochMax) * 100);
    if (progress === 0 && epoch_finished > 1) {
      progress = 1;
    } else if (progress === 100 && !isExperimentSucceeded(task.Status)) {
      progress = 99;
    }
  }
  return progress;
}

export function SetStatisticsEle(evalResult) {
  let statistics = '';
  let chart_title = '';
  if (evalResult && evalResult.train_result && !evalResult.errorMsg) {
    const chartkpi = ['loss', 'val_loss'];
    statistics = parseEvalDataToChart(chartkpi, evalResult.train_result);
    chart_title = 'Loss';
  }
  if (evalResult && evalResult.errorMsg) {
    statistics = -1;
  }
  return { chart_title, statistics };
}

export function SetEvalResultElm(evalResult) {
  let result = { title: 'unknown', value: 'NA' };
  if (evalResult && evalResult.eval_result && !evalResult.errorMsg) {
    const keykpi = evalResult.key_kpi;
    if (evalResult.eval_result[keykpi] != undefined) {
      if (keykpi == 'val_loss' || keykpi == 'loss')
        result = {
          title: keykpi,
          value: evalResult.eval_result[keykpi].toExponential(),
        };
      else
        result = {
          title: keykpi,
          value: `${(evalResult.eval_result[keykpi] * 100).toFixed(2)}%`,
        };
    }
  }
  return result;
}

export function getMaxGPUNum(
  deployType,
  userMaxGPU,
  projectMaxGPU,
  modelMaxGPU,
) {
  if (userMaxGPU < 0) return userMaxGPU;

  let maxGPUs = 1;
  const configGPUs = modelMaxGPU;
  maxGPUs = userMaxGPU;
  if (configGPUs != undefined) {
    if (configGPUs > -1) {
      maxGPUs = Math.min(configGPUs, userMaxGPU);
    }
  } else {
    maxGPUs = 1;
  }
  return maxGPUs;
}
