import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chat: false,
  sessionKeys: {},
  activeChatTopic: '',
  activeChatTopicId: '',
  chatConfig: {},
  completeHistoryMessage: {},
  sendChatRequest: {},
  createTopicInfo: {},
  topicInfo: {},
  topicInfoList: [],
  topics: [],
  checkIsInputValidHandler: undefined,
  sendCreateNewTopicRequest: {},
  nextMessageNeedSend: '',
  selectedExampleIdx: -1,
  chatExtraInfo: null,
  editUploadedDocument: undefined,
  editUploadedDocumentIndex: undefined,
  refreshChat: false,
  showProgressBar: false,
  uploadProgress: 0,
  fileList: [],
  submitUploadedDocument: false,
  keepHistoryNum: null,
  uploadResponse: {},
  uploadResponseStatus: {},
  defaultResponseStyle: '',
  defaultLlmModel: '',
  llmModel: [],
  selectedTopicId: null,
  editTopic: false,
  historicalDocumentInfoForTopic: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    initChatState: () => {
      return initialState;
    },
    setChat: (state, action) => {
      state.chat = action.payload;
    },
    setSessionKeys: (state, action) => {
      state.sessionKeys = action.payload;
    },
    setActiveChatTopic: (state, action) => {
      state.activeChatTopic = action.payload;
    },
    setActiveChatTopicId: (state, action) => {
      state.activeChatTopicId = action.payload;
    },
    setChatConfig: (state, action) => {
      state.chatConfig = action.payload;
    },
    setCompleteHistoryMessage: (state, action) => {
      state.completeHistoryMessage = action.payload;
    },
    setSendChatRequest: (state, action) => {
      state.sendChatRequest = action.payload;
    },
    setTopicInfo: (state, action) => {
      state.topicInfo = action.payload;
    },
    setTopicInfoList: (state, action) => {
      state.topicInfoList = action.payload;
    },
    setTopics: (state, action) => {
      state.topics = action.payload;
    },
    setCheckIsInputValidHandler: (state, action) => {
      state.checkIsInputValidHandler = action.payload;
    },
    setSendCreateNewTopicRequest: (state, action) => {
      state.sendCreateNewTopicRequest = action.payload;
    },
    setNextMessageNeedSend: (state, action) => {
      state.nextMessageNeedSend = action.payload;
    },
    setSelectedExampleIdx: (state, action) => {
      state.selectedExampleIdx = action.payload;
    },
    setChatExtraInfo: (state, action) => {
      state.chatExtraInfo = action.payload;
    },
    setEditUploadedDocument: (state, action) => {
      state.editUploadedDocument = action.payload;
    },
    setEditUploadedDocumentIndex: (state, action) => {
      state.editUploadedDocumentIndex = action.payload;
    },
    setRefreshChat: (state, action) => {
      state.refreshChat = action.payload;
    },
    setShowProgressBar: (state, action) => {
      state.showProgressBar = action.payload;
    },
    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
    setFileList: (state, action) => {
      state.fileList = action.payload;
    },
    setSubmitUploadedDocument: (state, action) => {
      state.submitUploadedDocument = action.payload;
    },
    setKeepHistoryNum: (state, action) => {
      state.keepHistoryNum = action.payload;
    },
    setUploadResponse: (state, action) => {
      state.uploadResponse = action.payload;
    },
    setUploadResponseStatus: (state, action) => {
      state.uploadResponseStatus = action.payload;
    },
    setDefaultResponseStyle: (state, action) => {
      state.defaultResponseStyle = action.payload;
    },
    setDefaultLlmModel: (state, action) => {
      state.defaultLlmModel = action.payload;
    },
    setLlmModel: (state, action) => {
      state.llmModel = action.payload;
    },
    setSelectedTopicId: (state, action) => {
      state.selectedTopicId = action.payload;
    },
    setCreateTopicInfo: (state, action) => {
      state.createTopicInfo = action.payload;
    },
    setEditTopic: (state, action) => {
      state.editTopic = action.payload;
    },
    setHistoricalDocumentInfoForTopic: (state, action) => {
      state.historicalDocumentInfoForTopic = action.payload;
    },
  },
});

export const {
  initChatState,
  setChat,
  setSessionKeys,
  setDefaultResponseStyle,
  setDefaultLlmModel,
  setLlmModel,
  setActiveChatTopic,
  setActiveChatTopicId,
  setChatConfig,
  setCompleteHistoryMessage,
  setSendChatRequest,
  setCreateTopicInfo,
  setTopicInfo,
  setTopicInfoList,
  setTopics,
  setSelectedTopicId,
  setEditTopic,
  setCheckIsInputValidHandler,
  setSendCreateNewTopicRequest,
  setNextMessageNeedSend,
  setSelectedExampleIdx,
  setChatExtraInfo,
  setEditUploadedDocument,
  setEditUploadedDocumentIndex,
  setRefreshChat,
  setShowProgressBar,
  setUploadProgress,
  setFileList,
  setSubmitUploadedDocument,
  setKeepHistoryNum,
  setUploadResponse,
  setUploadResponseStatus,
  setHistoricalDocumentInfoForTopic,
} = slice.actions;

export default slice.reducer;
