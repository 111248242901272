exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".lIjkDmyaSmedsDuXYfq7i {\n    left: 10px;\n    max-width: 500px;\n    max-height: 200px;\n    background: rgba(4,10,18,.9);\n    position: fixed;\n    bottom: 10px;\n    text-align: center;\n    padding: 30px;\n    color: #8FCE00;\n    z-index: 50;\n    min-width: 350px\n}\n\n._3eNQBdiCbhcQ7jtkagXtnL:hover {\n    background: #0062cc;\n    color: white;\n}\n\n._3eNQBdiCbhcQ7jtkagXtnL:active {\n    background: #003d7f\n}", ""]);

// exports
exports.locals = {
	"info--wrapper": "lIjkDmyaSmedsDuXYfq7i",
	"btn--winblue": "_3eNQBdiCbhcQ7jtkagXtnL"
};