import { fromBinary, getCookie } from '../common';

export const apiOptions = () => {
  const original_user_info = getCookie('original_user_info');
  const username = original_user_info
    ? JSON.parse(fromBinary(atob(original_user_info))).name
    : '';

  return {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${username}`,
    },
  };
};

export const api_options_get = () => {
  let bearer_token = getCookie('access_token') ?? '';
  const original_user_info = getCookie('original_user_info');

  if (window.AUTH_URL === '')
    bearer_token = original_user_info
      ? JSON.parse(fromBinary(atob(original_user_info))).name
      : '';

  const api_option = {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearer_token}`,
    },
  };

  return api_option;
};

export const api_options_delete = () => {
  const api_option = api_options_get();
  api_option.method = 'DELETE';

  return api_option;
};

export const api_options_post = () => {
  const api_option = api_options_get();
  api_option.method = 'POST';

  return api_option;
};

export const api_options_post_json = () => {
  const api_option = api_options_get();
  api_option.method = 'POST';
  api_option.headers['Content-Type'] = 'application/json';

  return api_option;
};

export const api_options_put = () => {
  const api_option = api_options_get();
  api_option.method = 'PUT';

  return api_option;
};

export const api_options_put_json = () => {
  const api_option = api_options_get();
  api_option.method = 'PUT';
  api_option.headers['Content-Type'] = 'application/json';

  return api_option;
};

export const api_options_delete_json = () => {
  const api_option = api_options_get();
  api_option.method = 'DELETE';
  api_option.headers['Content-Type'] = 'application/json';

  return api_option;
};
