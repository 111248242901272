import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groupList: [],
  selectedGroup: {},
  solutionList: [],
  modelOptionsList: [],
  lineOptionsList: [],
  selectedModel: '',
  selectedModelToken: '',
  selectedLines: [],
  filteredSolutions: [],
  selectedTime: {},
  selectedStartTime: {},
  selectedEndTime: {},
  lot: [],
  status: '',
  prodNum: [],
};

const smtMultiSearchSlice = createSlice({
  name: 'smtMultiSearch',
  initialState,
  reducers: {
    setGroupList: (state, action) => {
      state.groupList = action.payload;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    setSolutionList: (state, action) => {
      state.solutionList = action.payload;
    },
    setModelOptionsList: (state, action) => {
      state.modelOptionsList = action.payload;
    },
    setLineOptionsList: (state, action) => {
      state.lineOptionsList = action.payload;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    setSelectedModelToken: (state, action) => {
      state.selectedModelToken = action.payload;
    },
    setSelectedLines: (state, action) => {
      state.selectedLines = action.payload;
    },
    setFilteredSolutions: (state, action) => {
      state.filteredSolutions = action.payload;
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    initQueryState: (state, action) => {
      return initialState;
    },
    setSelectedStartTime: (state, action) => {
      state.selectedStartTime = action.payload;
    },
    setSelectedEndTime: (state, action) => {
      state.selectedEndTime = action.payload;
    },
    setLot: (state, action) => {
      state.lot = [...action.payload];
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setProdNum: (state, action) => {
      state.prodNum = [...action.payload];
    },
  },
});

export const {
  setGroupList,
  setSelectedGroup,
  setSolutionList,
  setModelOptionsList,
  setLineOptionsList,
  setSelectedModel,
  setSelectedModelToken,
  setSelectedLines,
  setFilteredSolutions,
  setSelectedTime,
  initQueryState,
  setSelectedStartTime,
  setSelectedEndTime,
  setLot,
  setStatus,
  setProdNum,
} = smtMultiSearchSlice.actions;

export default smtMultiSearchSlice.reducer;
