import { ServerAPIPrefix } from '../config/config';

function httpTestLDAPURL(context, data, handlers) {
  const settings = {
    url: ServerAPIPrefix + 'ldap/test',
    type: 'POST',
    contentType: 'application/json',
    context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpUpdateLDAPSetting(context, data, handlers) {
  const settings = {
    url: ServerAPIPrefix + 'ldap/setting',
    type: 'POST',
    contentType: 'application/json',
    context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpGetLDAPSetting(context, handlers) {
  const settings = {
    url: ServerAPIPrefix + 'ldap/setting',
    type: 'GET',
    context,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

export { httpTestLDAPURL, httpGetLDAPSetting, httpUpdateLDAPSetting };
