exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2jHswKhaWym8DZ9QkEOhtM {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    text-align: center;\n}\n\n._1CD1bWt7PpTIAVu9VNeoi8{\n    color: #c9c9c9;\n}\n\n._1Zhsig8M2xdBnlXhop03T0{\n    color: #2d3143;\n}", ""]);

// exports
exports.locals = {
	"loading": "_2jHswKhaWym8DZ9QkEOhtM",
	"icon": "_1CD1bWt7PpTIAVu9VNeoi8",
	"icon-dark": "_1Zhsig8M2xdBnlXhop03T0"
};