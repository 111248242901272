import { getCookie } from '../common';
import {
  LabelPlatformServerPrefix,
  LabelPlatformServerV2Prefix,
  ServerURL,
} from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';
import { api_options_get } from './apiOptions';

export async function httpGetLabelPlatformTasks(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}${param.paramSearch}`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpGetCategoryLabel(token) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${token}/project_config`;

  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpPostCategoryLabel(payload) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${payload.token}/categories`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      label_type: payload.data.labelType,
      categories: payload.data.categories,
    }),
  });

  return returnResponse(response);
}

export async function httpPUTCategoryLabel(payload) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${payload.token}/jobs/${payload.jobId}/categories`;
  const data = {
    label_type: payload.data.labelType,
    categories: payload.data.categories,
  };

  if (payload.data.jobName) {
    data.job_name = payload.data.jobName;
  }

  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(data),
  });

  return returnResponse(response);
}
export async function httpReqNewJobID(payload) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${payload.token}/jobs${payload.groupToken ? `?groupToken=${payload.groupToken}` : ''}`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
  });

  return returnResponse(response);
}

export async function httpGetJobImageProgress(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobID}/job_image_progress?insert_id=${param.insertID}`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpPostDeleteImages(payload) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${payload.token}/jobs/${payload.jobID}/delete_images`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      project_token: payload.token,
      job_id: payload.jobID,
      image_list: payload.imageList,
    }),
  });

  return returnResponse(response);
}

export async function httpPostSetTracking(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobID}/set_tracking`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({ is_update_ground_truth: param.value }),
  });

  return returnResponse(response);
}

export async function httpGetAssignMember(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobID}/assign_members`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpGetConsensusSetting(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobID}/consensus_settings`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpPostCreateJob(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobID}/assign_members?source_project_token=${param.sourceProjectToken}`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      job_name: param.job_name,
      site_url: param.site_url,
      consensus: param.consensus,
      selected_user_list: param.selected_user_list,
    }),
  });

  return returnResponse(response);
}

export async function httpGetTasks(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobID}/tasks`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function http_Get_Model_All_List(token) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${token}/model_all_list`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpGetModelList(token) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${token}/model_list`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpGetModelCategoryList(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/model_category_list`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      model_name: param.model_name,
      model_version: param.model_version,
      model_weight: param.model_weight,
    }),
  });

  return returnResponse(response);
}

export async function httpGetJobs(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpDeleteJob(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobId}`;
  const response = await fetch(url, {
    ...options,
    headers: { ...options.headers, 'X-Requested-With': 'XMLHttpRequest' },
    method: 'DELETE',
  });

  return returnResponse(response);
}

export async function httpGetModelUsage(projectToken) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${projectToken}/model_usage`;
  const response = await fetch(url, options);
  return returnResponse(response);
}

export async function httpGetDatasetList(projectToken) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${projectToken}/jobs?is_create_dataset=1`;
  const response = await fetch(url, options);
  return returnResponse(response);
}

export async function httpGetLabelStatusList(payload) {
  const options = getDefaultOptions();

  const { projectToken, jobId } = payload;
  const url = `${LabelPlatformServerPrefix}projects/${projectToken}/jobs/${jobId}/label_status_list`;
  const response = await fetch(url, options);
  return returnResponse(response);
}

export async function httpGetLabelStatus(payload) {
  const options = getDefaultOptions();

  const { projectToken, jobId } = payload;
  const url = `${LabelPlatformServerPrefix}projects/${projectToken}/jobs/${jobId}/label_status`;
  const response = await fetch(url, options);
  return returnResponse(response);
}

export async function httpUpdateLabelStatusThreshold(payload) {
  const options = getDefaultOptions();

  const { projectToken, jobId, threshold, is_polygon_rle } = payload;
  const url = `${LabelPlatformServerPrefix}projects/${projectToken}/jobs/${jobId}/label_status_list`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({ threshold, is_polygon_rle }),
  });
  return returnResponse(response);
}

export async function httpGetDatasetFormData(payload) {
  const options = getDefaultOptions();

  const { projectToken, jobId } = payload;
  const url = `${LabelPlatformServerPrefix}projects/${projectToken}/jobs/${jobId}/create_dataset`;
  const response = await fetch(url, options);
  return returnResponse(response);
}

export async function httpCheckDatasetName(payload) {
  const options = getDefaultOptions();

  const { projectToken, datasetName } = payload;
  const url = encodeURI(
    `${LabelPlatformServerPrefix}projects/${projectToken}/check_dataset_name/${datasetName}`,
  );
  const response = await fetch(url, options);
  return returnResponse(response);
}

export async function httpCreateDataset(payload) {
  const options = getDefaultOptions();

  const { projectToken, jobId, data } = payload;
  const url = `${LabelPlatformServerPrefix}projects/${projectToken}/jobs/${jobId}/create_dataset`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...options.headers,
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  return returnResponse(response);
}

export async function httpGetCreateDatasetProgress(datasetPK) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}datasets/${datasetPK}/dataset_progress`;
  const response = await fetch(url, options);
  return returnResponse(response);
}

export async function httpGetAvailableEdgeList(token) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${token}/get_available_edge_list`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpPostScheduleDataCollect(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/schedules`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      job_name_prefix: param.config.jobNamePrefix,
      auto_pack: {
        description: param.config.description,
        sample_count: param.config.sampleCount,
        timeout: param.config.timeout,
        is_set_no_incoming_timeout: param.config.isSetNoIncomingTimeout,
        device_list: param.deviceList,
        weekday_list: param.config.weekdayList,
      },
      start_date: param.config.startDate,
      final_date: param.config.finalDate,
    }),
  });

  return returnResponse(response);
}

export async function httpGetAutoPackingProgress(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobId}/auto_packing_progress`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpDeleteSchedule(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/schedules/${param.scheduleId}`;
  const response = await fetch(url, {
    ...options,
    headers: { ...options.headers, 'X-Requested-With': 'XMLHttpRequest' },
    method: 'DELETE',
  });

  return returnResponse(response);
}

export async function httpPostUpdateAutoPackingTimeout(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobId}/auto_packing_signal`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({
      file_count: -1,
      timeout: param.timeout,
    }),
  });

  return returnResponse(response);
}

export async function httpGetJobCategoryList(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobId}/job_category_list`;
  const response = await fetch(url, {
    ...options,
  });

  return returnResponse(response);
}

export async function httpPostSaveAutoAnnotationModel(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}api/lambda/requests`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    headers: {
      ...options.headers,
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFToken': getCookie('csrftoken'),
      Accept: 'application/json, text/javascript, */*; q=0.01',
    },
    body: JSON.stringify({
      cleanup: param.cleanup,
      task: param.cvatTaskId,
      function: param.modelName,
      model_version: param.modelVersion,
      model_weight: param.modelWeight,
      mapping: param.modelMapping,
      threshold: param.threshold,
      begin_frame_id: param.beginFrameId,
      end_frame_id: param.endFrameId,
    }),
  });

  return returnResponse(response);
}

export async function httpPostAutoAnnotationProgress(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.token}/jobs/${param.jobId}/auto_annotation_progress`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(param.data),
  });

  return returnResponse(response);
}

export async function httpDeleteAutoAnnotationProgress(requestJobId) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}api/lambda/requests/${requestJobId}`;
  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
    headers: {
      ...options.headers,
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFToken': getCookie('csrftoken'),
      Accept: 'application/json, text/javascript, */*; q=0.01',
    },
  });

  return returnResponse(response);
}

export async function httpGetMergeDatasetList(projectToken) {
  const options = getDefaultOptions();

  let url = `${LabelPlatformServerPrefix}merge_datasets`;

  if (projectToken) {
    url = `${LabelPlatformServerPrefix}projects/${projectToken}/dataset_list`;
  }

  const response = await fetch(url, options);
  return returnResponse(response);
}

export async function httpGetMergeDatasetProgress(mergeId) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}datasets/${mergeId}/merge_dataset_progress`;
  const response = await fetch(url, options);
  return returnResponse(response);
}

export async function httpPostMergeDataset(payload) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}datasets/merge_datasets`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { ...options.headers, 'X-Requested-With': 'XMLHttpRequest' },
  });
  return returnResponse(response);
}

export async function httpPostInserImageByFileToken(param) {
  const options = getDefaultOptions();

  const { token, jobId, fileTokens } = param;
  const url = `${LabelPlatformServerPrefix}projects/${token}/jobs/${jobId}/upsert_images_by_file_token`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(fileTokens),
  });
  return returnResponse(response);
}

export async function httpPostAutoTraining(param) {
  const options = getDefaultOptions();

  const url = `${LabelPlatformServerPrefix}projects/${param.project_token}/auto_training/${param.schedule_id}`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(param.auto_train),
    headers: { ...options.headers, 'X-Requested-With': 'XMLHttpRequest' },
  });
  return returnResponse(response);
}

export async function http_get_version(param) {
  const url = `${ServerURL}/api/v1/p/label_platform/version`;
  const response = await fetch(url, { ...api_options_get() });

  return returnResponse(response, true);
}

export async function httpPostCreateLabelJob({ payload, timeout }) {
  const options = getDefaultOptions();

  let url = `${LabelPlatformServerV2Prefix}mlops/create_label_job`;

  if (timeout) {
    url = url + `?timeout=${timeout}`;
  }

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      ...options.headers,
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFToken': getCookie('csrftoken'),
      Accept: 'application/json, text/javascript, */*; q=0.01',
    },
  });

  return returnResponse(response);
}

export async function httpGetJobsV2(param) {
  const options = getDefaultOptions();

  let url = `${LabelPlatformServerV2Prefix}projects/${param.token}/jobs`;
  if (param.is_manual === 'manual') {
    url = `${url}?is_manual=1`;
  } else if (param.is_manual === 'auto') {
    url = `${url}?is_manual=0`;
  }
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFToken': getCookie('csrftoken'),
      Accept: 'application/json, text/javascript, */*; q=0.01',
    },
  });

  return returnResponse(response);
}

export async function httpPostAppendImagesToLabelJob({ payload, timeout }) {
  const options = getDefaultOptions();

  let url = `${LabelPlatformServerV2Prefix}mlops/append_images_to_label_job`;

  if (timeout) {
    url = url + `?timeout=${timeout}`;
  }

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      ...options.headers,
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFToken': getCookie('csrftoken'),
      Accept: 'application/json, text/javascript, */*; q=0.01',
    },
  });

  return returnResponse(response);
}
