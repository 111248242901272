import { fromBinary, getCookie } from '../common';
import { ServerAPIPrefix } from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';

export const httpGetLLMList = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/llm_model/list?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response, true);
};

export const httpEditLLMListOrder = async (llm_id_list) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/selection_order?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({ llm_id_list }),
  });
  return returnResponse(response, true);
};

export const httpGetLabelList = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/label_options?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response, true);
};

export const httpEditDefaultLLMLabel = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/default?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpGetMatchedLLMList = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/get_llm_list`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpGetModelTypeList = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/llm_model/edit_config`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response, true);
};

export const httpGetAllLabels = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/labels`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response, true);
};

export const httpCreateNewLLM = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/llm_model?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpEditLLM = async (id, payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/llm_model/${id}?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpDeleteLLM = async (id) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/llm_model/${id}?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
    method: 'DELETE',
  });
  return returnResponse(response, true);
};

export const httpGetUserLabels = async (user) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/user/permission/${user}?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response, true);
};

export const httpChangeDefaultLLM = async (llm_id) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/default/${llm_id}?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
  });
  return returnResponse(response, true);
};

export const httpTestLLM = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/llm_model/test?admin=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, false);
};

export const httpCheckPermission = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}chatgpt/llm/check_permission?username=${atob(getCookie('user'))}`;
  const response = await fetch(url, {
    ...options,
  });
  return returnResponse(response, false);
}