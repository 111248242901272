import { getDefaultLanguage } from './common';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../assets/i18n/en/translation.json';
import en_billing from '../assets/i18n/en/billing.json'
import en_label_platform from '../assets/i18n/en/label_platform.json';
import en_license from '../assets/i18n/en/license.json';
import en_group from '../assets/i18n/en/group.json';
import en_serving from '../assets/i18n/en/serving.json';
import en_solution from '../assets/i18n/en/solution.json';
import en_dataset from '../assets/i18n/en/dataset.json';
import en_smt_rejudge from '../assets/i18n/en/smt_rejudge.json';
import enChat from '../assets/i18n/en/chat.json';

import tw from '../assets/i18n/zh-TW/translation.json';
import tw_billing from '../assets/i18n/zh-TW/billing.json';
import tw_common from '../assets/i18n/zh-TW/common.json';
import tw_label_platform from '../assets/i18n/zh-TW/label_platform.json';
import tw_license from '../assets/i18n/zh-TW/license.json';
import tw_edge from '../assets/i18n/zh-TW/edge.json';
import tw_gpu from '../assets/i18n/zh-TW/gpu.json';
import tw_group from '../assets/i18n/zh-TW/group.json';
import tw_serving from '../assets/i18n/zh-TW/serving.json';
import tw_solution from '../assets/i18n/zh-TW/solution.json';
import tw_dataset from '../assets/i18n/zh-TW/dataset.json';
import tw_smt_rejudge from '../assets/i18n/zh-TW/smt_rejudge.json';
import twChat from '../assets/i18n/zh-TW/chat.json';


const resources = {
    en: {
        translation: en,
        billing: en_billing,
        label_platform: en_label_platform,
        license: en_license,
        group: en_group,
        serving: en_serving,
        solution: en_solution,
        dataset: en_dataset,
        smt_rejudge: en_smt_rejudge,
        chat: enChat
    },
    'zh-TW': {
        translation: tw,
        billing: tw_billing,
        common: tw_common,
        label_platform: tw_label_platform,
        license: tw_license,
        edge: tw_edge,
        gpu: tw_gpu,
        group: tw_group,
        serving: tw_serving,
        solution: tw_solution,
        dataset: tw_dataset,
        smt_rejudge: tw_smt_rejudge,
        chat: twChat
    },
};


var default_lan = getDefaultLanguage();
window.language = default_lan;

i18n.use(initReactI18next)
    .init({
        resources,
        lng: default_lan,
        fallbackLng: default_lan,
        ns: ['translation'],
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
        debug: false,
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
    }, (err, t) => {
        console.debug(err, t);
    });

export {
    i18n
};
