export const jobStatusMapping = (status) => {
  switch (status) {
    case 'NEW':
      return 'New';
    case 'PENDING':
      return 'Pending';
    case 'STARTED':
      return 'Running';
    case 'SUCCEEDED':
      return 'Success';
    case 'FAILED':
      return 'Failed';
    case 'RUNNING':
      return 'Running';
    case 'ERROR':
      return 'Error';
    default:
      return status;
  }
};

import moment from 'moment';

export const transferTimeFormat = (time, format) => {
  return moment(time).utcOffset('+08:00').format(format);
};

export const diffMonths = (dt2, dt1) => {
  const dt2Str = moment(dt2).format('YYYY-MM-DD');
  const dt1Str = moment(dt1).format('YYYY-MM-DD');
  return moment(dt2Str).diff(dt1Str, 'months');
};
