import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavbarList } from './NavBar';
import { UserOptMenuWithTranslation as UserOptMenu } from '../user_profile';
import { ConfigLanguage } from './ConfigLanguage';
import logo from '../../assets/images/logo.png';
import { createCookie, deleteCookie, getCookie } from '../common';
import { checkIsFunctionEnabled } from '../utils/common_utils';
import { http_get_version } from '../api/labelPlatform';

function Header() {
  const location = useLocation();
  const { template_token } = useParams();
  const navigate = useNavigate();

  const getLogoLink = () => {
    if (location.pathname.startsWith('/smtrejudge') && template_token) {
      return `/smtrejudge/stations/${template_token}`;
    }
    return '/';
  };

  const clearAllCookies = () => {
    document.cookie.split(';').forEach((cookie) => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
  };

  const headerStyle = {
    zIndex: 999,
    width: '100%',
    height: '80px',
    position: 'sticky',
    backgroundColor: '#151515',
  };

  const headerBarStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  };

  const smtheaderBarStyle = {
    position: 'fixed',
    top: '0',
    lefe: '0',
  };

  const logoStyle = {
    width: '275px',
    marginTop: '8px',
    marginRight: '1.5rem',
  };

  const toolBarStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  useEffect(() => {
    console.log('UseEffect for get version');
    if (checkIsFunctionEnabled('ENABLE_CVAT')) {
      http_get_version()
        .then((response) => {
          let authentication_token = getCookie('authentication_token');
          deleteCookie('authentication_token');
          localStorage.setItem('token', authentication_token);
        })
        .catch((reason) => {
          console.log('[Root] reason: ', reason);
          clearAllCookies();
          createCookie('RedirectURL', window.location.href);
          navigate('/login');
        });
    }
  }, []);

  return (
    <>
      {window.location.pathname.startsWith('/smtrejudge') ? (
        <div
          style={{
            zIndex: '999',
            width: '100%',
            height: '80px',
            position: 'fixed',
            backgroundColor: '#151515',
          }}
        >
          <div className='col-centered' style={smtheaderBarStyle}>
            <div
              style={{
                width: '275px',
                marginTop: '20px',
                marginLeft: '3rem',
                position: 'fixed',
                top: '0',
              }}
            >
              <Link to={getLogoLink}>
                <img
                  style={{ height: '36px', width: 'auto', cursor: 'default' }}
                  src={logo}
                />
              </Link>
            </div>
            <div style={{ flexGrow: 1 }}>{/* <NavbarList /> */}</div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '6rem',
                position: 'fixed',
                right: '0',
                top: '20px',
              }}
            >
              {checkIsFunctionEnabled('ENABLE_SWITCH_LANGUAGE') && (
                <div>
                  <ConfigLanguage />
                </div>
              )}
              <div>
                <UserOptMenu />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={headerStyle}>
          <>
            <div className='width80 col-centered' style={headerBarStyle}>
              <div style={logoStyle}>
                <Link to='/'>
                  <img
                    style={{
                      height: '36px',
                      width: 'auto',
                    }}
                    src={logo}
                  />
                </Link>
              </div>
              <div style={{ flexGrow: 1 }}>
                {window.ENABLE_AI_ASSISTANT_STANDALONE === 'false' && (
                  <NavbarList />
                )}
              </div>
              <div style={toolBarStyle}>
                {checkIsFunctionEnabled('ENABLE_SWITCH_LANGUAGE') && (
                  <div>
                    <ConfigLanguage />
                  </div>
                )}
                <div>
                  <UserOptMenu />
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
}

export default Header;
