import React, { useEffect } from 'react'
import classes from "./PlatformWarning.module.css"

export const LicenseErrorCode = {
    "427": {"msg": (<span>You are using trial version of PEGA AI and only limited functions are enabled  on this version</span>), "needActivate" : true},
    "402": {"msg": (<span>Your PEGA AI license is expired.<br />Extend your PEGA AI license to continue to use it</span>), "needActivate" : true},
    "430": {"msg": (<span>Your PEGA AI license is exceed the maximum instance.<br />Upgrade your PEGA AI license to continue to use it</span>), "needActivate" : true},
    "431": {"msg": (<span>Cannot validate your PEGA AI license.<br />You cannot use PEGA AI now</span>), "needActivate" : true},
    "432": {"msg": (<span>Your PEGA AI license is invalid.<br />You cannot use PEGA AI now</span>), "needActivate" : true},
    "433": {"msg": (<span>Your system's time has been changed.<br />You cannot use PEGA AI now</span>), "needActivate" : false},
    "500": {"msg": (<span>Something went wrong.<br />Cannot validate your PEGA AI license</span>), "needActivate" : false}
}

export const LicenseWarning = (props) => {
    const {setShowClientActivation} = props
    const {errorCode} = props

    const msgErrorCode = LicenseErrorCode[errorCode] ? errorCode : "500" //prevent unknown error number

    return (
        <React.Fragment>

            <div>
                <div>
                    {LicenseErrorCode[msgErrorCode].msg}
                </div>
                {LicenseErrorCode[msgErrorCode].needActivate &&
                    <React.Fragment>
                        <br />
                        <div>
                            <button className={`btn btn-md btn--winblue allowed-without-license ${classes['btn--winblue']}`}
                                onClick={() => {setShowClientActivation(true)}}
                            >
                                Activate PEGA AI
                            </button>
                        </div>
                    </React.Fragment>
                }
            </div>

        </React.Fragment>
    )
}
