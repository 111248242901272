export const RECOMMENDED_IMAGE_COUNT = 50;

export const IMAGE_COLLECTOR_TYPE = {
  LOCAL: 'local',
  WEBCAM: 'webcam',
};

export const STEP_POINT = {
  1: 'dataSelect',
  2: 'manageLabel',
  3: 'train',
};

export const checkNewTrainingExist = (callback) => {
  const jobId = localStorage.getItem('job_id');
  const cvatInsertId = localStorage.getItem('cvat_insert_id');

  if (jobId || cvatInsertId) {
    toastr.info('It seems that there is a new training in progress now');
    return;
  }

  callback && callback();
};

export const clearStorageItems = (deepClear = true) => {
  if (deepClear !== false) {
    sessionStorage.removeItem('job_id');
    sessionStorage.removeItem('cvat_insert_id');
  }

  if (deepClear === 'all') {
    sessionStorage.removeItem('projectTrainConfig');
    sessionStorage.removeItem('from');
  }
  sessionStorage.removeItem(`labeling_cvat_task`);
  sessionStorage.removeItem('cvat_task_id');
  sessionStorage.removeItem('cvat_url');
  sessionStorage.removeItem('datasetsForTraining');
  sessionStorage.removeItem('datasetImported');
  sessionStorage.removeItem('source');
  sessionStorage.removeItem('photo_list_token');
  sessionStorage.removeItem('datasetImported');
  sessionStorage.removeItem(`total_photo`);
};
