import React, { Fragment, Suspense } from 'react';
import { Outlet } from "react-router-dom";
import Header from './HeaderBar/Header';
import PlatformWarning from './Platform/PlatformWarning';
import Loading from './UI/Loading';
import { usePureStoreSelector } from './store/pureStore';
import { getSystemConfig } from './utils/common_utils';

function Layout() {
    const licenseInfo = usePureStoreSelector(state => state.license.licenseInfo)

    return (
        <Fragment>
            <Header />

            {getSystemConfig("REQUIRED_LICENSE") === true && licenseInfo.isLicenseValid === false &&

                <div className={"blocked-layer"}/>
            }

            <Suspense fallback={<Loading />}>
                <div className={getSystemConfig("REQUIRED_LICENSE") === true && licenseInfo.isLicenseValid === false ? "invalid-license" : ""}>
                    <Outlet />
                </div>
            </Suspense>
            <PlatformWarning />
        </Fragment>
    )
}

export default Layout;