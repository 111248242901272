import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGetJobParameter } from '../api/smt_rejudge';

const initialState = {
  inputConfig: {},
  input: {},
};

export const fetchJobInput = createAsyncThunk(
  'jobInput/fetchJobInput',
  async (payload, thunkApi) => {
    const { solutionName, user, site } = payload;
    const response = await httpGetJobParameter(solutionName, user, site);
    return response;
  },
);

const jobInputSlice = createSlice({
  name: 'jobInput',
  initialState,
  reducers: {
    setJobInput: (state, action) => {
      const { key, value } = action.payload;
      if (state.inputConfig[key]?.type === 'str') {
        state.input = { ...state.input, [key]: value };
      } else {
        state.input = { ...state.input, [key]: +value };
      }
    },
    initJobInputState: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobInput.fulfilled, (state, action) => {
      const inputs = structuredClone(action.payload.input);
      delete inputs['TNR Threshold'];
      state.input = inputs;
      state.inputConfig = action.payload.template_input;
    });
  },
});

export const { setJobInput, initJobInputState } = jobInputSlice.actions;
export default jobInputSlice.reducer;
