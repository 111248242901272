import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTime: '',
  selectedResult: {},
  selectedVersion: [],
  selectedPartId: {},
  selectedPartNumber: '',
  modelToken: '',
  rejudgeData: {},
  templateToken: null,
  selectedSolution: null,
  summaryInfo: {},
  productNumberList: [],
  pagination: null,
};

const smtRejudgeSlice = createSlice({
  name: 'smtRejudge',
  initialState,
  reducers: {
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    setRejudgeData: (state, action) => {
      state.rejudgeData = action.payload;
    },
    setSelectedResult: (state, action) => {
      state.selectedResult = action.payload;
    },
    setSelectedVersion: (state, action) => {
      state.selectedVersion = action.payload;
    },
    setSelectedPartId: (state, action) => {
      state.selectedPartId = action.payload;
    },
    setSelectedPartNumber: (state, action) => {
      state.selectedPartNumber = action.payload;
    },
    setModelToken: (state, action) => {
      state.modelToken = action.payload;
    },
    setTemplateToken: (state, action) => {
      state.templateToken = action.payload;
    },
    initQueryState: (state, action) => {
      return initialState;
    },
    setSelectedSolution: (state, action) => {
      state.selectedSolution = action.payload;
    },
    setProductNumberList: (state, action) => {
      state.productNumberList = action.payload;
    },
    setSummaryInfo: (state, action) => {
      state.summaryInfo = action.payload;
    },
    editProductNumberList: (state, action) => {
      const { type, subtype, value } = action.payload;
      const itemIndex = state.productNumberList.findIndex(
        (item) => item.type === type,
      );
      if (itemIndex !== -1) {
        state.productNumberList[itemIndex].data[subtype] = value;
      }
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
});

export const {
  setSelectedTime,
  setRejudgeData,
  setSelectedResult,
  setSelectedVersion,
  setSelectedPartId,
  setSelectedPartNumber,
  setModelToken,
  setTemplateToken,
  initQueryState,
  setSelectedSolution,
  setProductNumberList,
  setSummaryInfo,
  editProductNumberList,
  setPagination,
} = smtRejudgeSlice.actions;

export default smtRejudgeSlice.reducer;
