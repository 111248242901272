import { returnResponse } from './api/apiConfig';
import * as Config from './config/config';

function httpGetUser(context, name, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users/name/' + name,
    type: 'GET',
    context: context,
    cache: false,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpGetStandardUser(context, data, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users/standard',
    type: 'POST',
    contentType: 'application/json',
    context: context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpGetUsers(context, role, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users/role/' + role,
    type: 'GET',
    context: context,
    cache: false,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpSearchLdapUser(context, username, domain, handlers) {
  var settings = {
    url:
      Config.ServerAPIPrefix +
      'ldap/search?_user=' +
      username +
      '&_domain=' +
      domain,
    type: 'GET',
    context: context,
    cache: false,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpSearchLdapUserWithoutDomain(context, username, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'ldap/search?_user=' + username,
    type: 'GET',
    context: context,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpCreateUser(context, data, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users',
    type: 'POST',
    //dataType: 'json',
    contentType: 'application/json',
    context: context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

async function httpCreateUserByInvitation(data) {
  const url = `${Config.ServerAPIPrefix}users/invite`;
  var settings = {
    type: 'POST',
    //dataType: 'json',
    contentType: 'application/json',
  };
  const response = await fetch(url, {
    ...settings,
    method: 'POST',
    body: JSON.stringify(data),
  });

  return returnResponse(response);
}

function httpUpdateUser(context, data, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users/update/' + data.Name,
    type: 'POST',
    contentType: 'application/json',
    context: context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpDeleteUser(context, username, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users/id/' + username,
    type: 'DELETE',
    context: context,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpSetUserUsages(context, data, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'userusage',
    type: 'POST',
    contentType: 'application/json',
    context: context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpGetUserUsages(context, starttime, endtime, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'userusage/' + starttime + '/' + endtime,
    type: 'GET',
    context: context,
    cache: false,
    timeout: 600000,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpGetUserLanguage(context, name, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users/language/' + name,
    type: 'GET',
    context: context,
    cache: false,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpGetUsersByUserName(context, data, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users/emails?_type=byUserName',
    type: 'POST',
    contentType: 'application/json',
    context: context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function httpResetPassword(context, data, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users/password',
    type: 'PUT',
    contentType: 'application/json',
    context: context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

function http_password_change(context, data, handlers) {
  var settings = {
    url: Config.ServerAPIPrefix + 'users/password_change',
    type: 'PUT',
    contentType: 'application/json',
    context: context,
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

export {
  httpGetUser,
  httpGetUsers,
  httpSearchLdapUser,
  httpSearchLdapUserWithoutDomain,
  httpCreateUser,
  httpUpdateUser,
  httpDeleteUser,
  httpSetUserUsages,
  httpGetUserUsages,
  httpGetUserLanguage,
  httpGetUsersByUserName,
  httpGetStandardUser,
  httpResetPassword,
  http_password_change,
  httpCreateUserByInvitation,
};
