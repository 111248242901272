import React, {useState, useCallback, useEffect} from 'react'
import useIsVisble from '../CustomHook/use-Visible';
import classes from "./PromotionalVideos.module.css"
import videoThumbnail from '../../assets/images/background-video.jpg'
import playThumbnail from '../../assets/images/play.png';

const PromotionalVideosCard = (props) => {
    const { list } = props;

    return (
        <div className={classes['image-list']}>
            {
                list.map((item, idx) => (
                        <PromotionCardContent
                            key={`${idx}`}
                            item={item}
                        />
                    )
                )
            }
        </div>
    )
}

const LazyImage = (props) => {

    const { placeholderImg, src, errorImg, ...restProps } = props;

    const [imgSrc, setSrc] = useState(placeholderImg || src);

    const onLoad = useCallback(() => {
        setSrc(src);
    }, [src]);

    const onError = useCallback(() => {
        setSrc(errorImg || placeholderImg);
    }, [errorImg, placeholderImg]);

    useEffect(() => {
        if (!src) return;
        const img = new Image();
        img.src = src;
        img.addEventListener("load", onLoad);
        img.addEventListener("error", onError);

        return () => {
            img.removeEventListener("load", onLoad);
            img.removeEventListener("error", onError);
        };

    }, [src, onLoad, onError]);

    return (
        <img {...restProps} src={imgSrc} />
    )
}
const PromotionCardContent = (props) => {
    const { item } = props;
    const [activeVideo, setActiveVideo] = useState("")
    const [elementRef, isVisible] = useIsVisble();

    const openVideo = () => {
        setActiveVideo(item.path)
    }

    return (
        <React.Fragment>

            {activeVideo !== "" &&
                <div className={classes["overlay"]}>
                    <a href="" className={classes["closebtn"]} onClick={(e) => {setActiveVideo(""); e.preventDefault()}}>&times;</a>
                    <div className={classes["overlay-content"]}>
                        <video style={{objectFit: "fill", maxHeight: "100%", maxWidth: "100%"}} controls autoPlay controlsList="nodownload">
                            <source src={item.path} type="video/mp4" />
                            <source src={item.path} type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            }

            <div
                ref={elementRef}
                className={`${classes['image-item']} ${classes['item--wrapper']} feature-usage`}
                onClick={openVideo}
            >
                <div className={classes['image-container']}>
                    {
                        isVisible &&
                        <LazyImage src={item.thumbnail ? item.thumbnail : videoThumbnail} placeholderImg={videoThumbnail} />
                    }
                    <img className={classes['app-play']} src={playThumbnail}/>
                    <span className={classes['app-name']}>
                        {item.title}
                    </span>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PromotionalVideosCard