import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  subMessage: '',
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.show = true;
    },
    hideLoading: (state) => {
      state.show = false;
    },
    setSubMessage: (state, action) => {
      state.subMessage = action.payload;
    },
  },
});

export const { showLoading, hideLoading, setSubMessage } = loadingSlice.actions;
export default loadingSlice.reducer;
