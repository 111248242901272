import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  location: {},
  action: {},
  viewer: '',
  isStreamingDone: false,
};

const slice = createSlice({
  name: 'pegaverse',
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setViewer: (state, action) => {
      state.viewer = action.payload;
    },
    setIsStreamingDone: (state, action) => {
      state.isStreamingDone = action.payload
    }

  },
});

export const { setLocation, setAction, setViewer, setIsStreamingDone } = slice.actions;

export default slice.reducer;
