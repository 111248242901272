import React, {useCallback, useEffect, useState} from 'react'
import classes from "./PlatformWarning.module.css"
import {LicenseWarning, LicenseErrorCode} from './LicenseWarning'
import { useLocation } from 'react-router-dom'
import ClientActivation from '../License/ClientActivation'
import { getSystemConfig } from '../utils/common_utils'
import { usePureStoreSelector } from '../store/pureStore';
import {promiseCheckClientLicense} from "../api/licenseClient"

const allowedClasses = ["btn-close", "allowed-without-license"]

const PlatformWarning = () => {
    const licenseInfo = usePureStoreSelector(state => state.license.licenseInfo)
    const location = useLocation()
    const [showClientActivation, setShowClientActivation] = useState(false)
    const requiredLicense = getSystemConfig("REQUIRED_LICENSE")
    const [abortControl, setAbortControl] = useState(null)

    useEffect(() => {
        if(requiredLicense){

            promiseCheckClientLicense()
        }
    }, [location, requiredLicense])

    const preventTab = useCallback((event) => {
        if(event.key != "Tab"){

            let inAllowedClasses = false
            event.target.classList.forEach(cls => {
                if(allowedClasses.indexOf(cls) > -1){
                    inAllowedClasses = true
                }
            })

            if(!inAllowedClasses) {
                event.preventDefault()
                event.stopPropagation()
            }
        }
    })

    useEffect(() => {
        let taskCheckLicense = null
        if(requiredLicense){

            taskCheckLicense = setInterval(() => {
                promiseCheckClientLicense()

            }, 60000)
        }

        return(() => {

            if(taskCheckLicense){
                clearInterval(taskCheckLicense)
            }

        })

    }, [requiredLicense])


    useEffect(() => {
        if(requiredLicense){
            if(licenseInfo.isLicenseValid === false){
                if(!abortControl){

                    const newAbortControl = new AbortController()
                    setAbortControl(newAbortControl)
                    document.addEventListener('keydown', preventTab, {signal: newAbortControl.signal})
                }
            }else{
                if(abortControl){
                    console.log("cc")
                    abortControl.abort()
                    setAbortControl(null)
                }
            }
        }
    }, [licenseInfo, requiredLicense])

    return (
        <React.Fragment>

            {requiredLicense && licenseInfo.isLicenseValid === false &&

                <div className={classes['info--wrapper']}>
                    <LicenseWarning errorCode={licenseInfo.errorCode} setShowClientActivation={(val) => {setShowClientActivation(val)}}/>
                </div>
            }

            {showClientActivation &&
                <ClientActivation
                    onCloseHandler={() => {setShowClientActivation(false)}}
                    onSuccessHandler={() => {setShowClientActivation(false); promiseCheckClientLicense()}}
                />

            }
        </React.Fragment>
    )


}

export default PlatformWarning