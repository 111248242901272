/*

    This is modal component.
    The purpose of this component is to show modal with header, body and footer (button).
    Default mode for this modal only has one button which is 'close'. Default is used to inform user.
    If you want to have two buttons, you can change the mode to 'confirm', which the buttons will be 'Yes' and 'No'


    Accepted properties:
        - title: text
        - body: components
        - mode: default, confirm, plain.
            - default contains button close  (textClose)
            - confirm contains button Yes (textConfirm) and No (textClose)
            - plain contains no button at all (some old components have confirm and close button. Therefore there is no need to have extra buttons)
        - textConfirm: any string for text of button confirm
        - textClose: any string for text of button close
        - onClose: event handler for close button
        - onConfirm: event handler for confirm button
        - isProcessing: boolean, indicate the UI is busy
        - closeButton: boolean, props to let modal show defaul close button on header
        - modalWrapperClass: class for modal-wrapper

*/

import classes from './Modal.module.css'
import { Modal as MD } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Loading from './Loading';

export const modalReducer = (state, action) => {
    return {...state, ...action.val }
}

export const defaultModalData = {
    body: null,
    title: null,
    mode: 'default',
    onConfirm: null,
    isProcessing: false,
    isOpenModal: false,
    textClose: null,
    textConfirm: null,
    modalWrapperClass: null,
    closeButton: false,
    onClose: null
}

const Modal = (props) => {

    const {t} = useTranslation()
    const {textClose, mode, onClose, onConfirm, textConfirm, body, title, isProcessing, closeButton, withoutOverflow} = props
    const modalWrapperClass = `${props.modalWrapperClass ? classes['modal-wrapper'] + ' ' + props.modalWrapperClass : classes['modal-wrapper']}`
    const modalBodyWrapperClass = `${props.modalBodyWrapperClass ? classes['modal-body--wrapper'] + ' ' + props.modalBodyWrapperClass : (props.newModalBodyWrapper?classes['modal-body-new--wrapper']:classes['modal-body--wrapper'])}`
    const modalParentBodyWrapperClass = `${props.modalParentBodyWrapperClass ? classes['modal-body'] + ' ' + props.modalParentBodyWrapperClass : (props.darkMode?classes['modal-body-dark']:classes['modal-body'])}`
    const modalHeaderClass = `${props.modalHeaderClass ? classes['modal-header'] + ' ' + props.modalHeaderClass : classes['modal-header']}`

    let modalMode = mode

    if(!modalMode){
        modalMode = 'default'
    }

    return (
        <MD
            show={true}
            onHide={onClose}
            backdrop="static"
            dialogClassName={modalWrapperClass}
        >
            <MD.Header className={modalHeaderClass} closeButton= {closeButton ? true : false}>
                <MD.Title className={classes['modal-title']}>{title}</MD.Title>
            </MD.Header>

            { <div className={isProcessing ? 'loading--all-screen' : 'display-none'}>
                <Loading variant="dark" />
            </div> }

            <MD.Body className={modalParentBodyWrapperClass}>
                {props.withoutOverflow ?
                    <div>
                        {body}
                    </div>:
                    <div className={modalBodyWrapperClass}>
                        {body}
                    </div>
                }
                    

                {modalMode !== 'plain' &&

                    <div className={`${classes['modal-footer']}`}>
                        {modalMode == 'confirm' &&
                            <React.Fragment>
                                <button
                                    className={props.darkMode?`btn submit-gray-bule-type btn__outline--light ${classes['btn-confirm']}`:`btn submit-gray-bule-type btn__outline--dark ${classes['btn-confirm']}`}
                                    onClick={onConfirm}
                                    disabled={props.disableConfirmButton?props.disableConfirmButton:false}
                                >
                                    {
                                        textConfirm ?
                                            <span>{textConfirm}</span>
                                        :
                                            <span>{t("button.yes")}</span>
                                    }
                                </button>
                                &nbsp;&nbsp;&nbsp;
                            </React.Fragment>
                        }
                        {!closeButton &&
                            <button
                                className={props.darkMode?"btn submit-gray-bule-type btn__outline--light":"btn submit-gray-bule-type btn__outline--dark"}
                                onClick={onClose}
                                disabled={props.disableCloseButton?props.disableCloseButton:false}
                            >
                                {
                                    textClose ? textClose
                                    : modalMode == 'default' ?
                                        t("button.no")
                                        :
                                        modalMode == 'confirm' ? t("button.no") : t("button.close")
                                }
                            </button>
                        }
                    </div>
                }

            </MD.Body>
        </MD>
    )
}

export default Modal