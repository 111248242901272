/*

    This is loading component.
    The purpose of this component is to show loading spinner.

    Accepted properties:
        - variant: this is for style the color. It accepts variant='dark'. If it's omitted, it will use default color

*/

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import classes from './Loading.module.css';

const BodyLoading = (props) => {
    return(
        <FontAwesomeIcon
            className={props.variant === 'dark' ? classes['icon-dark'] : classes['icon']}
            icon={solid('spinner')} spin size="2x"
        />
    )
}

const Loading = (props) => {
    const {mode, variant} = props
    return (
        <React.Fragment>
            {mode == "full-page" &&
                <div className={"blocked-layer"} style={{zIndex: 999, justifyContent: "center", display: "flex", alignItems: "center"}}>
                    <BodyLoading variant={variant}/>
                </div>
            }
            {mode != "full-page" &&
                <div className={`width80 col-centered ${classes['loading']}`}>
                    <BodyLoading  variant={variant}/>
                </div>
            }
        </React.Fragment>
    )
}

export default Loading;