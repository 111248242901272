// request to check license is using useHttp and because modelTrainBoardslice is also using useHttp,
// therefore it cause circular import. So that the slice for licesing and platformwarning is putting
// to different store (which is here)

import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import licenseReducer from './licenseSlice';
import platformWarningReducer from './platformWarningSlice';
import { createDispatchHook, createSelectorHook } from 'react-redux';

const pureStore = configureStore({
  reducer: {
    license: licenseReducer,
    platformWarning: platformWarningReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default pureStore;

export const storePureStoreContext = React.createContext();
export const usePureStoreDispatch = createDispatchHook(storePureStoreContext);
export const usePureStoreSelector = createSelectorHook(storePureStoreContext);
